import React from "react";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import isUrlHttp from "is-url-http";
import QRCode from "qrcode";
import Tabview from "./Tabview";
import {
	addTabToBin,
	deleteUserBin,
	updateTabinTitle,
} from "../features/tabin/tabinSlice";
import { Mixpanel } from "../app/mixpanel-instance";

function Tabs({ tabins, setTabins, binId }) {
	const TAG = "components.Tabs";
	console.log(TAG);

	const [addTabUrl, setAddTabUrl] = useState("");
	const [isValidUrl, setIsValidUrl] = useState(true);
	const [tabsData, setTabsData] = useState([]);
	const [binData, setBinData] = useState([]);
	const [showData, setShowData] = useState(false);
	const [title, setTitle] = useState("");
	const [isEditing, setIsEditing] = useState(false);
	const titleRef = useRef(null);

	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);

	useEffect(() => {
		let data = tabins.find((bin) => {
			return bin._id === binId;
		});
		setBinData(data);
		// console.log("data", data);
		if (data) {
			setTabsData(data.tabs);
			setShowData(true);
		}
	}, [binId, tabins, setTabsData, setBinData]);

	// console.log(TAG + "tabsData: ", tabsData);

	const clearInvalidUrlMessage = () => {
		setIsValidUrl(true);
	};

	const addTabBtnClickHandler = async () => {
		const validUrl = isUrlHttp(addTabUrl);

		if (validUrl) {
			setIsValidUrl(true);

			const actionResult = await dispatch(
				addTabToBin({ binId: binData._id, tabData: addTabUrl })
			);
			const newTab = actionResult.payload.new_tab;

			setTabsData((currentTabs) => [...currentTabs, newTab]);
			setTabins((currentTabins) => {
				const updatedTabins = [...currentTabins];
				const binIndex = updatedTabins.findIndex(
					(bin) => bin._id === binData._id
				);

				if (binIndex !== -1) {
					// Create a new object for the bin, with the updated tabs array
					const updatedBin = {
						...updatedTabins[binIndex],
						tabs: [...updatedTabins[binIndex].tabs, newTab],
					};

					// Replace the old bin object with the updated one
					updatedTabins[binIndex] = updatedBin;
				}
				setAddTabUrl("");
				return updatedTabins;
			});
		} else {
			setIsValidUrl(false);
		}
	};

	const delBtnClickHandler = async (e) => {
		let binId = e.target.id;
		await deleteBin(binId);
	};

	const deleteBin = async (binId) => {
		await dispatch(deleteUserBin(binId));
		setTabins((oldTabins) => {
			return oldTabins.filter((bin) => bin._id !== binId);
		});
		setShowData(false);
		Mixpanel.track("tabin deleted frontend", {
			tabin_id: binId,
		});
	};
	const copyBtnClickHandler = (e) => {
		const url = getTabinLink(e.target.id);
		navigator.clipboard.writeText(url);
		Mixpanel.track("tabin copied frontend", {
			tabin_url: url,
		});
	};
	const openBtnClickHandler = (e) => {
		const url = getTabinLink(e.target.id);
		window.open(url);
		Mixpanel.track("tabin opened frontend", {
			tabin_url: url,
		});
	};
	// const createQRCodeBtnHandler = (e) => {
	// 	const url = getTabinLink(e.target.id);
	// 	console.log("url", url);
	// 	QRCode.toCanvas(document.getElementById("canvas"), url, function (error) {
	// 		if (error) console.error(error);
	// 		console.log("success!");
	// 	});
	// };
	const getTabinLink = (tabinId) => {
		const openTabinUrl = process.env.REACT_APP_OPEN_TABIN_URL;
		const url = openTabinUrl + tabinId;
		return url;
	};

	const handleBlur = () => {
		setIsEditing(false);
		const newTitle = titleRef.current.textContent;
		if (!newTitle.trim()) {
			titleRef.current.textContent = binData.title;
		} else if (newTitle !== title) {
			setTitle(newTitle);
			tabinChangetitle(newTitle);
		}
	};

	const tabinChangetitle = async (newTitle) => {
		if (binData) {
			const binId = binData._id;
			await dispatch(updateTabinTitle({ binId, newTitle }));

			setTabins((currentTabins) => {
				const updatedTabins = [...currentTabins];
				const binIndex = updatedTabins.findIndex(
					(bin) => bin._id === binData._id
				);

				if (binIndex !== -1) {
					// Create a new object for the bin, with the updated tabs array
					const updatedBin = {
						...updatedTabins[binIndex],
						title: newTitle,
					};

					// Replace the old bin object with the updated one
					updatedTabins[binIndex] = updatedBin;
				}
				return updatedTabins;
			});
		}
	};

	return (
		<div className='flex-row pb-[200px] xs:p-0 xs:w-[68%] sm:w-[80%] sm:p-2 md:w-[70%] md:pl-0 '>
			<div className='flex'>
				<label className='flex w-[100%] text-3xl justify-center items-center p-1 pb-10'>
					{binData ? (
						<h2
							ref={titleRef}
							contentEditable
							suppressContentEditableWarning={true}
							onBlur={handleBlur}
							onClick={() => setIsEditing(true)}
							className='text-3xl px-10 py-2'
						>
							{binData.title}
						</h2>
					) : (
						"Tabin Details"
					)}
				</label>
				{/* <canvas className='flex '>Canvas</canvas> */}
			</div>
			{showData ? (
				<>
					<ul className='flex-row w-[100%]'>
						<Tabview
							binId={binId}
							binData={binData}
							setBinData={setBinData}
							setTabins={setTabins}
							deleteBin={deleteBin}
						></Tabview>
					</ul>
					<div className='flex w-[100%] justify-center items-center'>
						<div className='inline-flex w-[100%] justify-center items-center mt-2 border-2 xs:w-[100%] md:w-[90%] xl:w-[80%]'>
							<input
								className={`flex w-[100%] h-14 justify-center items-center mx-4 border ${
									isValidUrl
										? "border-gray-300 focus:border-blue-500"
										: "border-red-500 focus:border-red-500"
								} rounded-md px-3 py-2 focus:outline-none`}
								placeholder='Enter Url'
								onFocus={clearInvalidUrlMessage}
								value={addTabUrl}
								onChange={(e) => setAddTabUrl(e.target.value)}
							></input>
							{!isValidUrl && (
								<p className='text-red-500 text-sm mt-1 mr-4'>Invalid URL</p>
							)}
							<div className='flex w-[6%] justify-end p-1'>
								<button
									className='grid btn bg-green-500 p-2 hover:bg-green-700'
									id='addtabbtn'
									onClick={addTabBtnClickHandler}
								>
									<p>add</p>
									<p>tab</p>
								</button>
							</div>
						</div>
					</div>

					<div className='w-[100%] pb-[150px] justify-center items-center mt-2 xs:flex-row sm:flex'>
						<button
							className='mt-2 justify-center items-center webkitfilter font-bold text-white bg-red-400 border-2 border-black p-3 mr-2 cursor-pointer hover:bg-red-600 xs:w-[80%] md:w-[300px]'
							type='button'
							id={binId}
							onClick={delBtnClickHandler}
						>
							Delete Tabin
						</button>
						<button
							className='mt-2 justify-center items-center webkitfilter font-bold text-white bg-green-500 border-2 border-black p-3 mr-2 cursor-pointer hover:bg-green-700 xs:w-[80%] md:w-[300px]'
							type='button'
							id={binId}
							onClick={openBtnClickHandler}
						>
							Go to Tabin page
						</button>

						<button
							className='mt-2 justify-center items-center webkitfilter font-bold text-white bg-blue-400 border-2 border-black p-3 mr-2 cursor-pointer hover:bg-blue-700 xs:w-[80%] md:w-[300px]'
							type='button'
							id={binId}
							onClick={copyBtnClickHandler}
						>
							Copy Tabin Link
						</button>
						{/* <button
							className='mt-2 justify-center items-center font-bold text-white bg-orange-400 border-2 border-black p-3 mr-2 cursor-pointer hover:bg-orange-700 xs:w-[80%] md:w-[300px]'
							type=''
							id={binId}
							onClick={createQRCodeBtnHandler}
						>
							Create QR Code
						</button> */}
					</div>
				</>
			) : (
				<li className='flex w-[800px] justify-center items-center text-lg pt-10'>
					Click on the Bins to the left to see details
				</li>
			)}
		</div>
	);
}

Tabs.propTypes = {
	tabins: PropTypes.object,
	setTabins: PropTypes.func,
	binId: PropTypes.string,
};

export default Tabs;
