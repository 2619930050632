import React from "react";
import Modal from "react-modal";
import moment from "moment";
import { RiDeleteBinLine } from "react-icons/ri";
import { IconContext } from "react-icons";

const EditEventModal = ({ isOpen, onClose, onClickDelete, editEvent }) => {
	const { title, start, end, id } = editEvent;
	// const [eventtitle, setTitle] = useState(editEvent.title);
	// const [start, setStart] = useState(editEvent.start);
	// const [end, setEnd] = useState(editEvent.end);

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
		},
	};

	const onSubmit = (event) => {
		event.preventDefault();
		// onEventClick({
		// 	title,
		// 	start,
		// 	end,
		// });
		onClose();
	};

	function onClickHandler() {
		onClickDelete({
			id,
		});
		console.log("onClickHandler event id:", id);
		onClose();
	}

	console.log(" EditEventModal editEvent", editEvent);

	return (
		<div className='flex relative'>
			<Modal
				style={customStyles}
				ariaHideApp={false}
				closeTimeoutMS={0}
				isOpen={isOpen}
				onRequestClose={onClose}
			>
				<div className='flex-row'>
					<div className='flex pb-2'>
						<div className='flex h-[30px] w-[100%] font-bold text-xl justify-center items-center border-b-2'>
							{title}
						</div>
					</div>

					<form onSubmit={onSubmit}>
						{/* <input
							type='text'
							placeholder='Title'
							value={title}
							onChange={(e) => console.log(e)}
						/> */}
						<div className='flex border-b-2'>
							<div className='flex'>
								<label className='p-1'>{moment(start).format("dddd")}</label>
								<label className='p-1'>{moment(start).format("MMMM-DD")}</label>
								<label className='p-1'>{moment(start).format("h:mm")}</label>
								{/* <Datetime value={start} onChange={(date) => setStart(date)} /> */}
							</div>
							<div className='flex'>
								<label className='p-1'>{moment(end).format("h:mm")}</label>
								{/* <Datetime value={end} onChange={(date) => setEnd(date)} /> */}
							</div>
						</div>
						<div className='flex justify-center items-center'>
							<IconContext.Provider value={{ color: "red", className: "" }}>
								<button onClick={onClickHandler} className=''>
									<RiDeleteBinLine className='flex h-[30px] w-[40px] m-2 opacity-50  hover:opacity-100' />
								</button>
							</IconContext.Provider>
							{/* <div className='flex w-[100%] justify-center items-center'>
							<button className='flex bg-green-500 rounded p-2 mt-8 hover:bg-green-300 hover:shadow-lg hover:shadow-black'>
								<label className='text-md font-bold'>Update Event</label>
							</button>
						</div> */}
						</div>
					</form>
				</div>
			</Modal>
		</div>
	);
};

export default EditEventModal;
