import React from "react";
import CookieConsent from "react-cookie-consent";
import { Mixpanel } from "../app/mixpanel-instance";
import Cookie from "js-cookie";

const CookieConsentBox = () => {
	const cookie_consent_string = "cookie_consent";

	let cookieConsent = Cookie.get("_tcc_");
	console.log("cookieConsent", cookieConsent);

	return (
		<>
			{cookieConsent ? (
				<></>
			) : (
				<div>
					<CookieConsent
						buttonClasses='border-2 border-black bg-[#f8fb55] rounded-full opacity-90 p-3 cursor-pointer filter drop-shadow-sm m-2 hover:bg-[#f4f800] hover:opacity-100 hover:drop-shadow-xl'
						location='bottom'
						buttonText='I Accept'
						cookieName='_tcc_'
						style={{ background: "#FF6E8B", border: "2px" }}
						buttonStyle={{
							color: "#000000",
							fontSize: "14px",
							background: "#f4f800",
							margin: "0px 200px 0px 0px",
							border: "px",
						}}
						expires={150}
						debug={true}
						acceptOnScroll={true}
						acceptOnScrollPercentage={10}
						onAccept={(acceptedByScrolling) => {
							if (acceptedByScrolling) {
								// triggered if user scrolls past threshold
								console.log("Accept was triggered by user scrolling");

								Mixpanel.track(cookie_consent_string, {
									by_I_accept_button: false,
									by_scrolling: true,
								});
							} else {
								Mixpanel.track(cookie_consent_string, {
									by_I_accept_button: true,
									by_scrolling: false,
								});
							}
						}}
					>
						<span className='text-black font-bold'>
							We Value Your Privacy:{" "}
						</span>
						<span className='text-black pl-1'>
							We use cookies to improve your experience on our site.{" "}
						</span>
						<span className='text-sm text-black pl-1'>
							By clicking "I Accept", you agree to our use of cookies.
							<a href='https://tabin.io/cookies'>
								<span className='pl-1 text-[#f8fb55]'>Cookie Policy</span>
							</a>
						</span>
					</CookieConsent>
				</div>
			)}
		</>
	);
};

export default CookieConsentBox;
