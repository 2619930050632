import React, { useEffect, useState } from "react";
import Cookie from "js-cookie";
import { gapi } from "gapi-script";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GoogleLogin } from "react-google-login";
import { FcCheckmark } from "react-icons/fc";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CALENDAR_CLIENTID;
const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
//const API_KEY = process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY;
const DISCOVERY_DOCS = [
	"https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
];
const SCOPES =
	"email profile https://www.googleapis.com/auth/calendar.events.readonly";

const GOOGLE_USER_INFO_API = `https://www.googleapis.com/oauth2/v3/userinfo`;

const TAG = "Welcome2";

//http://localhost:3000/tmp#state=pass-through%20value&access_token=ya29.a0AeDClZAmfNidczGViGrVhPpKtjxZ1pYWdCOo6lFmwDguannXQIlZPn5v997z04N1pT61q7JDy2L7Pjn5Zclfa5nuZ6Fvn_p-TchdfBlb5ss4dM0pRxxnm8OpiePtHtwDwN0sTwTakJ8XNeCOsCZvgQVxNuqlDqTn0OZn5avuaCgYKAQYSARISFQHGX2Mi5SXwhl044JDH-Y1qb5QjPw0175&token_type=Bearer&expires_in=3599&scope=profile%20https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/calendar.readonly

const Welcome2 = () => {
	const navigate = useNavigate();

	const [events, setEvents] = useState([]);
	const [user, setUser] = useState({});

	const getData = async () => {
		const hash = window.location.hash;
		const params = new URLSearchParams(hash.substring(1)); // Remove the '#' at the start
		const accessToken = params.get("access_token");

		if (accessToken) {
			window.history.replaceState(null, null, " ");
			// Fetch calendar events for tomorrow
			console.log("getting user info");
			try {
				const userInfoResponse = await fetch(GOOGLE_USER_INFO_API, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				});
				const profileData = await userInfoResponse.json();
				console.log("profile Data", profileData);
				setUser(profileData);
				// setEvents(eventsData.items); // Set eventsData.items, not eventsData
				// Cookie.set("_gceve", JSON.stringify(eventsData.items));
				// console.log("Tomorrow's events: ", eventsData.items);

				//navigate("/welcome-3");

				// After fetching events, mark user as signed in to remove Google button
			} catch (error) {
				console.error("Error fetching calendar events: ", error);
			}
			// Get tomorrow's date in the required format (ISO 8601)
			const tomorrow = new Date();
			tomorrow.setDate(tomorrow.getDate() + 1);
			const startOfDay = new Date(tomorrow.setHours(0, 0, 0, 0)).toISOString();
			const endOfDay = new Date(
				tomorrow.setHours(23, 59, 59, 999)
			).toISOString();

			// Fetch calendar events for tomorrow
			console.log("getting calendar data");
			try {
				const eventsResponse = await fetch(
					`https://www.googleapis.com/calendar/v3/calendars/primary/events?timeMin=${startOfDay}&timeMax=${endOfDay}&singleEvents=true&orderBy=startTime`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
						},
					}
				);
				const eventsData = await eventsResponse.json();
				setEvents(eventsData.items); // Set eventsData.items, not eventsData
				Cookie.set("_gceve", JSON.stringify(eventsData.items));
				console.log("Tomorrow's events: ", eventsData.items);

				//navigate("/welcome-3");

				// After fetching events, mark user as signed in to remove Google button
			} catch (error) {
				console.error("Error fetching calendar events: ", error);
			}
		}
	};

	/*
	 * Create form to request access token from Google's OAuth 2.0 server.
	 */
	const oauthSignIn = () => {
		// Google's OAuth 2.0 endpoint for requesting an access token
		var oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

		// Create <form> element to submit parameters to OAuth 2.0 endpoint.
		var form = document.createElement("form");
		form.setAttribute("method", "GET"); // Send as a GET request.
		form.setAttribute("action", oauth2Endpoint);

		// Parameters to pass to OAuth 2.0 endpoint.
		var params = {
			client_id: CLIENT_ID,
			redirect_uri: APP_DOMAIN + "/welcome-2",
			response_type: "token",
			scope: SCOPES,
			include_granted_scopes: "true",
			state: "pass-through value",
			prompt: "consent",
		};

		// Add form parameters as hidden input values.
		for (var p in params) {
			var input = document.createElement("input");
			input.setAttribute("type", "hidden");
			input.setAttribute("name", p);
			input.setAttribute("value", params[p]);
			form.appendChild(input);
		}

		// Add form to page and submit it to open the OAuth 2.0 endpoint.
		document.body.appendChild(form);
		form.submit();
	};

	// Function to extract access token from URL
	const extractAccessToken = () => {
		const hash = window.location.hash;
		const params = new URLSearchParams(hash.substring(1)); // Remove the '#' at the start
		const accessToken = params.get("access_token");

		if (accessToken) {
			// Save access token to local storage
			console.log("google_access_token", accessToken);

			// Clear the URL fragment to hide the token
			window.history.replaceState(null, null, " ");
		}
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<div className='flex h-screen'>
			<div className='flex w-[60%] h-[90%] justify-center items-center  bg-yellow-50 mt-10 ml-10'>
				<div className='grid mt-[150px]'>
					{user && user.email ? (
						<>
							<div className='pb-10'>
								<h1 className='text-4xl font-semibold pb-3'>
									Your calendar is connected
								</h1>
								<p>
									This is the primary calendar that has your meetings for today
									& tomorrow.
								</p>
							</div>
							<div className='h-full'>
								<div className='flex h-[100px] w-full border border-b-2 rounded-xl bg-white text-black p-5 mb-[200px]'>
									<i className='flex justify-center items-center text-2xl mr-2'>
										<FcCheckmark />
									</i>
									<img className='rounded-full ml-3' src={user.picture}></img>
									<p className='flex justify-center items-center ml-4 text-xl font-medium'>
										{user.email}
									</p>
								</div>

								<button
									className='w-[60%] border border-b-2 rounded-full bg-green-300 text-white py-3 px-5 hover:bg-green-600 '
									onClick={() => {
										navigate("/welcome-3");
									}}
								>
									Continue
								</button>
							</div>
						</>
					) : (
						<>
							<div className='pb-10'>
								<h1 className='text-4xl font-semibold pb-3'>
									Connect Your Work Calendar
								</h1>
								<p>
									This is the primary calendar that has your meetings for
									tomorrow.
								</p>
							</div>

							<button
								className='googlebtn justify-center'
								onClick={oauthSignIn}
							>
								<span className='googlelogowhitebox'>
									<img
										className='googlelogo'
										src='google_logo.png'
										alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
									/>
								</span>
								CONNECT WORK CALENDAR
							</button>
						</>
					)}
				</div>
			</div>
			<div className='w-[40%] h-[90%] bg-yellow-100 mt-10 mr-10'>
				<div className='flex h-[100%] justify-center items-center'>
					<img className='w-full h-full' src='calendar.svg' />
				</div>
			</div>
		</div>
	);
};

export default Welcome2;
