import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tabinService from "./tabinService";

const TAG = "tabinslice";
const env = process.env.REACT_APP_ENV;
const tabin = JSON.parse(localStorage.getItem("bins"));
//const token = JSON.parse(localStorage.getItem("token"));
const initialState = {
	tabin: tabin ? tabin : null,
	isError: false,
	isSuccess: false,
	isLoading: false,
	message: null,
};

export const addTabToBin = createAsyncThunk(
	"data/addTabToTabin",
	async (data, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token;
			if (env === "development" || env === "DEV") {
				console.log("token from auth", token);
				console.log(TAG + ".getusertabins");
			}

			return await tabinService.addTabToBin(data, token);
		} catch (error) {
			const message =
				(error.response && error.response.data & error.data.message) ||
				error.message ||
				error.toString();

			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const getUserTabins = createAsyncThunk(
	"data/usertabins",
	async (googleId, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token;
			if (env === "development" || env === "DEV") {
				console.log("token from auth", token);
				console.log(TAG + ".getusertabins");
			}
			return await tabinService.getTabinsByGoogleId(googleId, token);
		} catch (error) {
			const message =
				(error.response && error.response.data & error.data.message) ||
				error.message ||
				error.toString();

			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const getTabinCenterTabins = createAsyncThunk(
	"data/tabincentertabins",
	async (thunkAPI) => {
		try {
			if (env === "development" || env === "DEV") {
				console.log(TAG + ".getTabinCenterTabins");
			}
			return await tabinService.getTabinCenterTabins();
		} catch (error) {
			const message =
				(error.response && error.response.data & error.data.message) ||
				error.message ||
				error.toString();

			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const deleteUserBin = createAsyncThunk(
	"data/deletetabin",
	async (binId, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token;
			if (env === "development" || env === "DEV") {
				console.log(TAG + ".deleteUserTabin");
			}
			return await tabinService.deleteUserBin(binId, token);
		} catch (error) {
			const message =
				(error.response && error.response.data & error.data.message) ||
				error.message ||
				error.toString();

			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const deleteTabFromBin = createAsyncThunk(
	"data/deletetabfromtabin",
	async (data, thunkAPI) => {
		const { binId, tabId } = data;
		try {
			const token = thunkAPI.getState().auth.token;
			if (env === "development" || env === "DEV") {
				console.log(TAG + ".deleteTabFromTabin binId", binId);
			}
			return await tabinService.deleteTabFromBin({ binId, tabId }, token);
		} catch (error) {
			const message =
				(error.response && error.response.data & error.data.message) ||
				error.message ||
				error.toString();

			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const getTabinByID = createAsyncThunk(
	"data/opentabin",
	async (binId, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token;
			if (env === "development" || env === "DEV") {
				console.log(TAG + ".openTabin");
			}
			return await tabinService.getTabinByID(binId, token);
		} catch (error) {
			const message =
				(error.response && error.response.data & error.data.message) ||
				error.message ||
				error.toString();

			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const updateTabinTitle = createAsyncThunk(
	"data/updateTabinTitle",
	async (data, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token;
			if (env === "development" || env === "DEV") {
				console.log(TAG + ".updateTabinTitle");
			}
			return await tabinService.updateTabinTitle(data, token);
		} catch (error) {
			const message =
				(error.response && error.response.data & error.data.message) ||
				error.message ||
				error.toString();

			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const dataSlice = createSlice({
	name: "data",
	initialState,
	reducers: {
		reset: (state) => {
			state.tabin = null;
			state.isError = false;
			state.isSuccess = false;
			state.isLoading = false;
			state.message = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(addTabToBin.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(addTabToBin.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				initialState;
				if (env === "development" || env === "DEV") {
					console.log("addtabtobin: ", action.payload);
				}
			})
			.addCase(addTabToBin.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(getUserTabins.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getUserTabins.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.tabin = action.payload;
			})
			.addCase(getUserTabins.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				state.tabin = null;
			})
			.addCase(deleteUserBin.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(deleteUserBin.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				if (env === "development" || env === "DEV") {
					console.log("deleteUserBin action.payload", action);
				}
				state.tabin = state.tabin.filter(
					(bin) => bin._id !== action.payload.bin_id
				);
			})
			.addCase(deleteUserBin.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(deleteTabFromBin.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(deleteTabFromBin.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				if (env === "development" || env === "DEV") {
					console.log("deleteTabFromBin action.payload", action.payload);
				}
				// Assuming action.payload contains { binId, tabId } of the deleted tab
				const { bin_id, tab_id } = action.payload;
				const binIndex = state.tabin.findIndex((bin) => bin._id === bin_id);
				if (binIndex !== -1) {
					// Filter out the deleted tab from the specific bin
					state.tabin[binIndex].tabs = state.tabin[binIndex].tabs.filter(
						(tab) => tab.id !== tab_id
					);
				}
				state.message = "Tab deleted successfully"; // or use action.payload.message if it exists
			})
			.addCase(deleteTabFromBin.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})
			.addCase(updateTabinTitle.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				if (env === "development" || env === "DEV") {
					console.log("updateTabinTitle action.payload", action.payload);
				}
				// Assuming action.payload contains { binId, tabId } of the deleted tab
				const { bin_id, title } = action.payload;
				const binIndex = state.tabin.findIndex((bin) => bin._id === bin_id);
				if (binIndex !== -1) {
					// Filter out the deleted tab from the specific bin
					state.tabin[binIndex].title = title;
				}
				state.message = "Tabin title updated successfully"; // or use action.payload.message if it exists
			})
			.addCase(updateTabinTitle.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			})

			// Inside extraReducers of dataSlice
			.addCase("INITIAL_STATE", (state) => {
				state.tabin = JSON.parse(localStorage.getItem("bins"));
				state.isError = false;
				state.isSuccess = false;
				state.isLoading = false;
				state.message = null;
			})
			// Inside extraReducers of dataSlice
			.addCase("RESET_DATA", (state) => {
				state.tabin = tabin;
				state.isError = false;
				state.isSuccess = false;
				state.isLoading = false;
				state.message = null;
			});
	},
});

export const resetData = () => ({ type: "RESET_DATA" });
export const { reset } = dataSlice.actions;
export default dataSlice.reducer;
