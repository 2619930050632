import axios from "axios";

const TABIN_API = process.env.REACT_APP_API;
const API_GET_TABINS_BY_USERID = process.env.REACT_APP_API_GET_TABINS_BY_USERID;
const API_GET_TABINS_BY_GOOGLEID =
	process.env.REACT_APP_API_GET_TABINS_BY_GOOGLEID;
const API_DELETE_TABIN = process.env.REACT_APP_API_DELETE_TABIN;
const API_TABIN_CENTER_TABINS = process.env.REACT_APP_API_TABIN_CENTER_TABINS;

const env = process.env.REACT_APP_ENV;
const TAG = "tabinservice.";

const addTabToBin = async (data, token) => {
	const { binId, tabData } = data;
	if (env === "development" || env === "DEV") {
		console.log(TAG + ".addTabToTabin");
		console.log("binId: " + binId);
		console.log("tabData: " + tabData);
	}
	// get auth
	const authHeaders = getAuthHeaders(token);

	// add tab to bin
	const response = await axios.post(
		API_DELETE_TABIN + binId + "/tab/",
		{
			tabData: tabData,
		},
		authHeaders
	);

	if (!response.data) {
		console.log("Error: something went wrong while adding a tab to tabin");
	}
	if (env === "development" || env === "DEV") {
		console.log("response data", response.data);
	}
	const tabin = JSON.parse(localStorage.getItem("bins"));
	const updatedTabin = tabin.map((bin) => {
		if (env === "development" || env === "DEV") {
			console.log("addtabtobin: bin._id ", bin._id);
			console.log("addtabtobin: action.payload.bin_id ", response.data.bin_id);
		}
		if (bin._id === response.data.bin_id) {
			if (env === "development" || env === "DEV") {
				console.log("updating bin with id: ", bin._id);
			}
			// For the bin that matches, return a new bin object with the new tab added
			return {
				...bin,
				tabs: [...bin.tabs, response.data.new_tab],
			};
		}
		// For bins that don't match, return the bin as is
		return bin;
	});

	localStorage.setItem("bins", JSON.stringify(updatedTabin));
	if (env === "development" || env === "DEV") {
		console.log("old tabin", tabin);
		console.log("modTabin", updatedTabin);
	}
	// const fakeresponse = {
	// 	success: true,
	// 	msg: "msg recieved",
	// };
	// return fakeresponse;
	return response.data;
};

const getTabinsByUserId = async (userid, token) => {
	if (env === "development" || env === "DEV") {
		console.log(TAG + ".getTabinsByUserId");
	}
	// get auth
	const authHeaders = getAuthHeaders(token);

	// get tabins
	const response = await axios.get(
		API_GET_TABINS_BY_USERID + userid,
		authHeaders
	);

	if (!response.data) {
		console.log("Error: something went wrong while reteriving user tabins");
	}
	console.log("response data", response.data);

	localStorage.setItem("bins", JSON.stringify(response.data));
	return response.data;
};

const getTabinsByGoogleId = async (googleId, token) => {
	if (env === "development" || env === "DEV") {
		console.log(TAG + ".getTabinsByGoogleId");
	}
	// get auth
	const authHeaders = getAuthHeaders(token);

	// get tabins
	const response = await axios.get(
		API_GET_TABINS_BY_GOOGLEID + googleId,
		authHeaders
	);

	if (!response.data) {
		console.log("Error: something went wrong while reteriving user tabins");
	}
	console.log("response data", response.data);

	localStorage.setItem("bins", JSON.stringify(response.data));
	return response.data;
};

const getTabinCenterTabins = async () => {
	if (env === "development" || env === "DEV") {
		console.log(TAG + ".getTabinCenterTabins");
	}
	// get auth

	// get tabins
	const response = await axios.get(API_TABIN_CENTER_TABINS);

	if (!response.data) {
		console.log("Error: something went wrong while reteriving user tabins");
	}
	console.log("response data", response.data);

	//localStorage.setItem("tabin_center_tabins", JSON.stringify(response.data));
	return response.data;
};

const deleteUserBin = async (tabinId, token) => {
	if (env === "development" || env === "DEV") {
		console.log(TAG + ".deleteTabin");
		console.log("tabinid", tabinId);
	}

	// get auth
	const authHeaders = getAuthHeaders(token);

	// delete tabin
	const response = await axios.delete(API_DELETE_TABIN + tabinId, authHeaders);
	if (!response.data) {
		console.log("Error: something went wrong while retrieving user tabins");
	}

	const { bin_id } = response.data;

	// Assuming response.data.deleted_bin_id is correct and present
	const storedBins = JSON.parse(localStorage.getItem("bins"));

	const updatedBins = storedBins.filter((bin) => bin._id !== bin_id);

	localStorage.setItem("bins", JSON.stringify(updatedBins));
	if (env === "development" || env === "DEV") {
		console.log("delete response data", response.data);
		console.log("delete response bin_id", bin_id);
		console.log("storedBins", storedBins);
		console.log("*****updatedBins******", updatedBins);
	}
	return response.data;
};

const deleteTabFromBin = async (data, token) => {
	if (env === "development" || env === "DEV") {
		console.log(TAG + ".deleteOneTabFromTabin");
	}
	const { binId, tabId } = data;

	// get auth
	const authHeaders = getAuthHeaders(token);

	// finalize api string
	const DELETE_ONE_TAB_FROM_TABIN = API_DELETE_TABIN + binId + "/tab/" + tabId;
	if (env === "development" || env === "DEV") {
		console.log(TAG, "deleteOneTabFromTabin");
		console.log("DELETE_ONE_TAB_FROM_TABIN: ", DELETE_ONE_TAB_FROM_TABIN);
		console.log("tabinid", binId);
		console.log("tabid", tabId);
	}

	// delete a tab from a bin
	const response = await axios.delete(DELETE_ONE_TAB_FROM_TABIN, authHeaders);
	if (!response.data) {
		console.log("Error: something went wrong deleting tab from bin");
		throw console.error(response);
	}
	if (env === "development" || env === "DEV") {
		console.log("delete response data", response.data);
	}

	const tabin = JSON.parse(localStorage.getItem("bins"));
	const updatedTabin = tabin.map((bin) => {
		// Find the bin that needs to be updated
		if (bin._id === response.data.bin_id) {
			// Filter out the tab that needs to be removed
			const updatedTabs = bin.tabs.filter(
				(tab) => tab.id !== response.data.tab_id
			);
			// Return a new bin object with the updated tabs array
			return {
				...bin,
				tabs: updatedTabs,
			};
		}
		// Return the bin unchanged if it's not the one we're updating
		return bin;
	});

	if (env === "development" || env === "DEV") {
		console.log("local storage setting bins");
		console.log("Updated bins", updatedTabin);
	}
	// Update the local storage with the new `tabin` array
	localStorage.setItem("bins", JSON.stringify(updatedTabin));

	return response.data;
};

const getTabinByID = async (binId, token) => {
	// get auth
	const authHeaders = getAuthHeaders(token);
	if (env === "development" || env === "DEV") {
		console.log(TAG, "getTabinByID");
		console.log("tabinid", binId);
	}

	// open tabin
	const response = await axios.get(TABIN_API + binId, authHeaders);
	if (env === "development" || env === "DEV") {
		console.log("response data", response.data);
	}
	return response.data;
};

const updateTabinTitle = async (data, token) => {
	const { binId, newTitle } = data;

	// get auth
	const authHeaders = getAuthHeaders(token);

	const api = TABIN_API + "title/" + binId;

	// make backend call
	const response = await axios.post(api, { newTitle }, authHeaders);
	if (env === "development" || env === "DEV") {
		console.log(TAG, "updatTabinTitle");
		console.log("data", data);
		console.log("api", api);
		console.log("updateTabinTitle response data", response);
	}

	const tabin = JSON.parse(localStorage.getItem("bins"));
	const updatedTabin = tabin.map((bin) => {
		// Find the bin that needs to be updated
		if (bin._id === response.data.bin_id) {
			// Filter out the tab that needs to be removed
			// Return a new bin object with the updated tabs array
			return {
				...bin,
				title: response.data.title,
			};
		}
		// Return the bin unchanged if it's not the one we're updating
		return bin;
	});

	if (env === "development" || env === "DEV") {
		console.log("local storage setting bins");
		console.log("Updated bins", updatedTabin);
	}
	// Update the local storage with the new `tabin` array
	localStorage.setItem("bins", JSON.stringify(updatedTabin));

	return response.data;
};

const getAuthHeaders = (token) => {
	const authHeaders = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return authHeaders;
};

const tabinService = {
	addTabToBin,
	getAuthHeaders,
	deleteUserBin,
	deleteTabFromBin,
	getTabinsByUserId,
	getTabinsByGoogleId,
	getTabinCenterTabins,
	getTabinByID,
	updateTabinTitle,
};

export default tabinService;
