import axios from "axios";
import Cookie from "js-cookie";
import { reset } from "../tabin/tabinSlice";

const REGISTER_USER_API = process.env.REACT_APP_USER_API;
const env = process.env.REACT_APP_ENV;
const TAG = "authService.";
// Register user or get user

const register = async (userData) => {
	if (env === "development" || env === "DEV") {
		console.log(TAG + ".register");
		console.log("ENVIRONMENT", process.env.REACT_APP_ENV);
		console.log("REGISTER_USER_API", REGISTER_USER_API);
	}
	const response = await axios.post(REGISTER_USER_API, userData);

	if (response.data) {
		if (env === "development" || env === "DEV") {
			console.log("register data: ", response.data);
		}
		Cookie.set("user", JSON.stringify(response.data.user));
		Cookie.set("token", JSON.stringify(response.data.token));
		localStorage.setItem("user", JSON.stringify(response.data.user));
		localStorage.setItem("token", JSON.stringify(response.data.token));
		if (env === "development" || env === "DEV") {
			console.log(Cookie.get("user"));
		}
	}

	return response.data;
};

const logout = async () => {
	if (env === "development" || env === "DEV") {
		console.log(TAG + ".logout");
		console.log("logging out.....", sessionStorage.getItem("user"));
	}
	localStorage.removeItem("user");
	localStorage.removeItem("token");
	localStorage.removeItem("bins");
	localStorage.removeItem("all_users");
	localStorage.removeItem("tabin_center_tabins");
	Cookie.remove("user");
	Cookie.remove("token");
	Cookie.remove("gevents");
	Cookie.remove("_gceve");
	reset();
};

const authService = {
	register,
	logout,
};

export default authService;
