/*global chrome*/

import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid"; // a plugin!
import ReactGA from "react-ga4";
import EditEventModal from "../components/EditEventModal";
import { getUserTabins } from "../features/tabin/tabinSlice";
import { Mixpanel } from "../app/mixpanel-instance";
import { Helmet } from "react-helmet";
const TAG = "pages.schedule";

const TABIN_EXT_ID = process.env.REACT_APP_TABIN_EXT_ID; //"ckgeooknaapofchjphkafcfjefflmaal"

console.log("TABIN_EXT_ID", TABIN_EXT_ID);

const Schedule = () => {
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [events, setEvents] = useState([]);
	const [editEvent, setEditEvent] = useState({});
	const calendarRef = useRef(null);

	const user = useSelector((state) => state.auth.user);
	const tabins = useSelector((state) => state.data.tabin);
	const dispatch = useDispatch();

	const onEventAdded = (event) => {
		console.log("event", event);

		let calendarApi = calendarRef.current.getApi();
		calendarApi.addEvent({
			start: moment(event.start).toDate(),
			end: moment(event.end).toDate(),
			title: event.title,
			binId: event.extendedProps.binId,
			userId: event.extendedProps.userId,
		});
		console.log("calendarapi: ", calendarApi);
	};

	async function handleEventAdd(data) {
		const eventData = {
			title: data.event.title,
			start: data.event.start,
			end: data.event.end,
			binId: data.event.extendedProps.binId,
			userId: data.event.extendedProps.userId,
		};
		console.log("posting data ....... handleEventAdd data:  ", eventData);

		// setStart(eventData.start);
		// setEnd(eventData.end);
		await axios.post("/api/calendar/create-event", eventData);
		await sendMessageToExtension();

		ReactGA.event({
			category: "User Engagement",
			action: "User added Calendar Event",
			label: "Tabin Interaction",
			value: user.email,
		});
	}

	async function sendMessageToExtension() {
		console.log("TABIN_EXT_ID", TABIN_EXT_ID);
		if (chrome && chrome.runtime) {
			console.log("Chrome runtime is available");
		}
		if (window.chrome && window.chrome.runtime) {
			console.log("sending message");

			try {
				await new Promise((resolve, reject) => {
					window.chrome.runtime.sendMessage(
						TABIN_EXT_ID,
						{ type: "scheduleTask" },
						(response) => {
							if (chrome.runtime.lastError) {
								console.error("Error:", chrome.runtime.lastError.message);
								reject(chrome.runtime.lastError.message);
							} else if (response && response.status === "success") {
								console.log("Task scheduled successfully");
								resolve();
							} else {
								console.error("Failed to schedule task");
								reject("Failed to schedule task");
							}
						}
					);
				});
			} catch (error) {
				console.error(
					"Extension is not installed or error in communication:",
					error
				);
			}
		} else {
			console.error("Chrome runtime API not available.");
		}
	}

	async function handleDatesSet(data) {
		console.log("Data ----", data);

		const response = await axios.get(
			"/api/calendar/get-events?start=" +
				moment(data.start).toISOString() +
				"&end=" +
				moment(data.end).toISOString()
		);

		console.log("handleDatesSet data: ", data);
		console.log("handleDatesSet response: ", response);

		setEvents(response.data);
	}

	async function handleEventChange(data) {
		console.log("posting data ....... handleEventChange data:  ", data);

		await axios.put("/api/calendar/update-event", data.event);
	}

	function onEventClick(data) {
		console.log("event id", data.event);

		setEditModalOpen(true);
		const event = {
			start: data.event.start,
			end: data.event.end,
			title: data.event.title,
			id: data.event.extendedProps._id,
		};
		setEditEvent(event);
	}

	function onEditModalClose() {
		setEditModalOpen(false);
	}

	const onClickDelete = async ({ id }) => {
		console.log("onClickDelete event id:", id);

		await axios.delete(`/api/calendar/delete-event/${id} `);
		setEvents((oldEvents) => {
			return oldEvents.filter((event) => event._id !== id);
		});

		await sendMessageToExtension();

		ReactGA.event({
			category: "User Engagement",
			action: "User deleted a Calendar Event",
			label: "Tabin Interaction",
			value: user.email,
		});
	};

	const headerToolbar = {
		left: "prev,next today",
		center: "title",
		right: "timeGridDay, timeGridWeek,",
	};

	let counter = 0;
	function eventReceiveHandler(data) {
		counter++;
		if (counter < 2) {
			console.log("eventReceiveHandler", data);
			console.log("eventReceiveHandler counter", counter);

			onEventAdded(data.event);
			sendMessageToExtension();
			setTimeout(function () {
				window.location.reload(false);
			}, 1000);
		}
	}

	const refreshBtnClickHandler = async () => {
		localStorage.removeItem("bins");

		console.log(TAG + ".btnclickhandler getting tabins from db");

		await dispatch(getUserTabins(user.google_id));
		window.location.reload(false);
	};

	// load external events
	useEffect(() => {
		Mixpanel.track_pageview();
		ReactGA.send({
			hitType: "pageview",
			page: "/schedule",
			title: "Schedule Event",
		});
		let draggableEl = document.getElementById("external-events");
		new Draggable(draggableEl, {
			itemSelector: ".fc-event",
			eventData: function (eventEl) {
				// console.log("eventEl", eventEl);

				let title = eventEl.getAttribute("title");
				let binId = eventEl.getAttribute("data");

				console.log("binId ", binId);

				return {
					title: title,
					binId: binId,
					userId: user._id,
					duration: "01:00",
					stick: true,
				};
			},
		});
	}, []);

	return (
		<>
			<Helmet>
				<title>
					Tabin | Schedule - Context Switching Made Easy To Increase Work
					Productivity
				</title>
				<link rel='canonical' href='https://tabin.io/schedule' />
			</Helmet>
			<section className='inline-flex w-[100%] h-[100%] pt-14'>
				<div className='block w-[22%] h-auto pt-[68px] pl-0 sm:pl-[10px] md:pl-[30px]'>
					{" "}
					<div className='flex justify-center items-center h-[65px] w-[100%] bg-[#FCF6DA] border-solid border-2 border-black sm:w-[94%]'>
						<div className='flex w-[100%] justify-center font-semibold xs:text-xl sm:text-3xl'>
							Tabins
						</div>
						<div
							className='flex w-[23%] justify-center font-semibold xs:text-xl sm:text-3xl opacity-60 webkitfilter hover:opacity-100'
							type='button'
							onClick={refreshBtnClickHandler}
						>
							<img
								src='refresh.png'
								alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
							></img>
						</div>
					</div>
					<div className='flex w-[100%] h-[auto] bg-[#AAF0DA] justify-center items-center border-solid border-[1px] border-black sm:w-[94%] sm:p-1 md:p-2'>
						<ul
							id='external-events'
							className='flex-row w-[100%] justify-center items-center m-1 p-1 sm:p-2'
						>
							{tabins.map((bin) => {
								return (
									<li
										draggable
										className='fc-event flex w-[100%] h-[60px] bg-white justify-center items-center rounded-none cursor-pointer mb-1 changeonhover sm:rounded-lg md:rounded-2xl md:mb-4 sm:p-1'
										title={bin.title}
										data={bin._id}
										key={bin._id}
									>
										<span
											className='flex w-[100%] text-xs justify-center items-center xs:text-sm sm:text-base md:text-lg'
											key={bin._id}
											id={bin._id}
										>
											{bin.title.length > 20
												? bin.title.slice(0, 18) + "...."
												: bin.title}
										</span>
									</li>
								);
							})}
						</ul>
					</div>
				</div>

				<div
					className='flex-row w-[335px] h-[800px] pl-1 ss:w-[500px] sm:w-[600px] sm:ml-3 sm:pl-3 md:w-[800px] lg:w-[900px] llg:w-[1100px] xl:w-[1300px] '
					style={{ position: "inherit", zIndex: 0 }}
				>
					<FullCalendar
						ref={calendarRef}
						events={events}
						plugins={[interactionPlugin, timeGridPlugin]}
						headerToolbar={headerToolbar}
						initialView='timeGridDay'
						eventAdd={(event) => handleEventAdd(event)}
						datesSet={(date) => handleDatesSet(date)}
						editable={true}
						eventChange={(event) => handleEventChange(event)}
						selectable={true}
						eventDurationEditable={true}
						eventResizableFromStart={true}
						eventClick={(event) => onEventClick(event)}
						droppable={true}
						eventDragStop={(info) => console.log("eventDragStop info", info)}
						externalDrop={true}
						dragRevertDuration={0}
						eventReceive={(data) => eventReceiveHandler(data)}
					/>
				</div>

				<EditEventModal
					isOpen={editModalOpen}
					onClose={() => onEditModalClose()}
					onEventClick={(event) => onEventClick(event)}
					editEvent={editEvent}
					onClickDelete={(id) => onClickDelete(id)}
				/>
			</section>
		</>
	);
};

export default Schedule;
