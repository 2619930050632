import axios from "axios";

const env = process.env.REACT_APP_ENV;
const USER_API = process.env.REACT_APP_USER_API;

const TAG = "userService.";

// delete single user on admin dashboard

const deleteSingleUser = async (user_id, token) => {
	/* https://tabin.io/api/user/:id */
	const API = USER_API + user_id;

	//  get auth
	const authHeaders = getAuthHeaders(token);
	const response = await axios.delete(API, authHeaders);

	const { del_user_id } = response.data;

	if (env === "development" || env === "DEV") {
		console.log(TAG + "deleteSingleUser");
		console.log("user_id", user_id);
		console.log("Delete user API", API);
		console.log("deleted user response data", response.data);
	}

	if (response.data) {
		const users = JSON.parse(localStorage.getItem("all_users"));
		const updatedUsers = users.filter((user) => user._id !== del_user_id);
		localStorage.setItem("all_users", JSON.stringify(updatedUsers));

		if (env === "development" || env === "DEV") {
			console.log("users", users);
			console.log("******updatedUsers********", updatedUsers);
		}

		return response.data;
	} else {
		console.log("Something went wrong, response.data is not valid");
		return response.data;
	}
};

// Get all users for admin dashboard

const getAllUsers = async (token) => {
	const GET_ALL_USER_API = USER_API + "all";

	if (env === "development" || env === "DEV") {
		console.log(TAG + "getAllUsers");
		console.log("ENVIRONMENT", process.env.REACT_APP_ENV);
		console.log("GET_ALL_USER_API", USER_API);
	}

	// get auth
	const authHeaders = getAuthHeaders(token);
	const response = await axios.get(GET_ALL_USER_API, authHeaders);

	if (response.data) {
		if (env === "development" || env === "DEV") {
			console.log("getAllUsers response ", response);
		}
		localStorage.setItem("all_users", JSON.stringify(response.data.users));
	}

	return response.data;
};

const updateSingleUser = async (user, token) => {
	const API = USER_API + user._id;

	if (env === "development" || env === "DEV") {
		console.log(TAG + "updateSingleUser");
		console.log("API", API);

		console.log("user", user);
	}

	//  get auth
	const authHeaders = getAuthHeaders(token);
	const response = await axios.put(API, user, authHeaders);

	if (response.data) {
		if (env === "development" || env === "DEV") {
			console.log("getAllUsers response ", response);
		}
		// localStorage.setItem("all_users", JSON.stringify(response.data.users));
	}

	return response.data;
};

const getAuthHeaders = (token) => {
	const authHeaders = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return authHeaders;
};

const userService = {
	deleteSingleUser,
	getAllUsers,
	updateSingleUser,
};

export default userService;
