import React from "react";
import styles from "../style";
import GetStartedButton from "./GetStartedButton";

const Hero = () => {
	return (
		<section
			id='home'
			className={`flex md:flex-row flex-col ${styles.paddingY} ${styles.flexCenter} `}
		>
			<div
				className={`flex-1 ${styles.flexStart} flex-col xl:px-12 sm:px-16 px-6`}
			>
				{/* <div className='flex flex-row items-center py-[6px] px-4 bg-discount-gradient rounded-[10px] pl-14 mb-2'>
					<p className={`${styles.paragraph} ml-2`}>
						<span className='text-black'>20% </span>Discount For{" "}
						<span className='text-black'>1 Month</span> Account
					</p>
				</div> */}
				<div className='flex flex-row jusitfy-between items-center w-full'>
					<h1 className='flex-1 font-poppins font-semibold ss:text-[72px] text-[52px]'>
						Easily Switch
						<br className='sm:block' />
						<span className='text-gradient'>Context</span>
						<p>between Meetings</p>
						<p>& Work</p>
						<div className='lg:hidden'>
							<img
								src='send_me_a_tabin.png'
								alt='Context Switching App to increase work productivity, single link to multiple links, take notes while attending meetings, save time and increase focus'
								className='justify-center items-center w-[90%] h-[90%] max-h-[800px] max-w-[800px] mt-12 ml-10 ss:pl-[100px] lg:hidden'
							/>
							<div className='absolute z-[0] w-[40%] h-[35%] top-40 pink__gradient '></div>
							<div className='absolute z-[1] w-[80%] h-[35%] rounded-full bottom-40 black __gradient'></div>
							<div className='absolute z-[0] w-[50%] h-[35%] right-22 bottom-50 blue__gradient rs:hidden lg:flex'></div>
						</div>
						<p className={`text-[17px] font-extralight py-10 w-full space-x-2`}>
							Create a Tabin for{" "}
							<span className='font-bold text-[#FF6E8B]'>meetings</span>,{" "}
							<span className='font-extrabold text-[#4fddb0]'>work</span>,
							<span className='font-bold text-[#FF6E8B]'>research</span> and
							<span className='font-extrabold text-[#4fddb0]'>leisure</span>.
							Boost your productivity and maximize your day with Tabin.
						</p>
					</h1>
				</div>
				<div className='flex h-auto w-full justify-center items-center'>
					<div className='flex  h-[120px] w-[500px] my-3 mb-8 md:my-10 md:mb-12'>
						<div className='flex m-2 p-2 justify-center items-center '>
							<a href='https://chromewebstore.google.com/detail/tabin-schedule-your-day/aikkjpmagfploeaedmhickkcadlfndno?hl=en&authuser=0'>
								<span className='flex h-auto w-auto px-12 justify-center items-center bg-[#f8fb55] border-2 rounded-full hover:bg-[#f4f800]'>
									<i className='flex flex-nowrap w-[full] h-[120px] justify-center items-center my-3 drop-shadow-sm opacity-70 hover:opacity-100 hover:drop-shadow-xl'>
										<img className='h-[75px]' src='tabin.png' alt='' />
										<span className='flex-row justify-center items-center mx-8 text-2xl font-medium'>
											<p>Get Tabin for </p>
											<p> chrome - It's Free</p>
										</span>
									</i>
								</span>
							</a>
						</div>
					</div>
				</div>
				<p className={`${styles.paragraph} max-w-[1100px] mt-5 `}>
					Tabin offers a seamless and efficient solution for sharing multiple
					links, ensuring a swift transition between various tasks, including
					work sessions and meetings. With Tabin, users can effortlessly enhance
					meeting efficiency and focus - minimizing distractions associated with
					context switching. Simply schedule tabins, attend meetings and
					complete work with confidence.
				</p>
			</div>
			<div
				className={`flex-1 flex ${styles.flexCenter} relative py-0 my-0 pl-10 xs:hidden md:my-0 lg:flex`}
			>
				<img
					src='send_me_a_tabin.png'
					alt='Context Switching App to increase work productivity, single link to multiple links, take notes while attending meetings, save time and increase focus'
					className='w-[100%] h-[90%] max-h-[800px] max-w-[800px] relative z-[5] my-10 sm:my-0 rs:hidden lg:flex'
				/>
				<div className='absolute z-[0] w-[40%] h-[35%] top-0 pink__gradient'></div>
				<div className='absolute z-[1] w-[80%] h-[80%] rounded-full bottom-40 black __gradient'></div>
				<div className='absolute z-[0] w-[50%] h-[50%] right-22 bottom-50 blue__gradient'></div>
			</div>
			{/* <div
				className={`ss:hidden flex ${styles.flexCenter} h-[120px] w-[120px]`}
			>
				<GetStartedButton />
			</div> */}
		</section>
	);
};

export default Hero;
