import React from "react";
import { useState } from "react";
import Modal from "react-modal";
import Toggle from "react-toggle";
import "react-toggle/style.css";

const DashboardModal = ({
	isOpen,
	onClose,
	toggleStatus,
	setToggleStatus,
	userEmail,
}) => {
	const customStyles = {
		content: {
			top: "40%",
			left: "70%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
		},
	};

	function onAdminToggle(e) {
		setToggleStatus(e.target.checked);
	}

	return (
		<div className=''>
			<Modal
				style={customStyles}
				ariaHideApp={false}
				closeTimeoutMS={0}
				isOpen={isOpen}
				onRequestClose={onClose}
			>
				<div className='flex-row'>
					<div className='flex pb-2'>
						<div className='flex h-auto w-auto font-bold text-md justify-center items-center'>
							{userEmail} Admin Status
						</div>
						<div className='flex w-[50%] justify-center items-center border-l-2'>
							<Toggle
								defaultChecked={toggleStatus}
								name='adminToggle'
								onChange={(e) => onAdminToggle(e)}
							/>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default DashboardModal;
