/* Libraries */
import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Mixpanel } from "../app/mixpanel-instance";
import ReactGA from "react-ga4";

/* Files */
import { deleteSingleUser } from "../features/user/userSlice";
import { logout, resetAuth } from "../features/auth/authSlice";
import { resetData } from "../features/tabin/tabinSlice";

const Settings = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const user = useSelector((state) => state.auth.user);

	function deleteBtnClickHandler() {
		dispatch(deleteSingleUser(user._id));
		dispatch(logout());
		dispatch(resetAuth());
		dispatch(resetData());

		Mixpanel.track("user delete", {
			user: user._id,
		});
		ReactGA.event({
			category: "User delete",
			action: "User delete",
			value: user._id,
		});
		navigate("/");
	}

	useEffect(() => {
		Mixpanel.track_pageview();
		if (user) {
			console.log("user", user.image_url);
		}
	}, []);

	return (
		<div className='p-6'>
			<Helmet>
				<title>
					Tabin | Settings - Context Switching Made Easy To Increase Work
					Productivity
				</title>
				<link rel='canonical' href='https://tabin.io/settings' />
			</Helmet>
			<div className='flex h-full w-full justify-center '>
				<div className='flex-row h-[800px] w-[800px] justify-start text-left'>
					<div className=''>
						<h1 className=' text-[40px]'>Profile</h1>
					</div>

					<div className='flex w-full text-left mt-10 items-center'>
						<div className='h-[90px] w-[90px] border-2 rounded-full overflow-hidden'>
							<img
								src={user.image_url}
								alt='User Profile'
								className='w-full h-full object-cover'
							/>
						</div>

						<div className='ml-4 text-left'>
							<h4 className='p-2'>
								Name: {user.first_name + " " + user.last_name}
							</h4>
							<h4 className='p-2 text-sm'>
								Joined:{" "}
								{new Date(user.createdAt).toLocaleDateString("en-US", {
									year: "numeric",
									month: "short",
								})}
							</h4>
						</div>
					</div>

					<div className='flex pt-10 w-full'>
						<div className='max-w-md text-left'>
							<h1 className='font-semibold text-lg'>Delete Tabin account</h1>
							<p className='mt-2'>
								If you no longer wish to use Tabin, you can remove all Tabin
								data including existing Tabins, scheduled settings, Tabin notes.
								This action is irreversible.
							</p>
							<button
								className='mt-10 justify-center items-center font-bold text-white bg-red-400 border-2 border-black p-3 mr-2 cursor-pointer hover:bg-red-600 xs:w-[80%] md:w-[300px]'
								type='button'
								onClick={deleteBtnClickHandler}
							>
								Delete Account
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Settings;
