import React from "react";
import { Mixpanel } from "../app/mixpanel-instance";

const YoutubeEmbed = ({ embedId }) => {
	Mixpanel.track("howto Video Played", {
		url: `https://www.youtube.com/embed/${embedId}`,
	});
	return (
		<div className='aspect-w-16 aspect-h-9'>
			<iframe
				className='w-[320px] h-[250px] sm:w-[620px] sm:h-[350px]'
				src={`https://www.youtube.com/embed/${embedId}`}
				allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
				allowFullScreen
				title='Embedded youtube'
			/>
		</div>
	);
};

export default YoutubeEmbed;
