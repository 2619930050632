import React from "react";
import { Link } from "react-router-dom";
import Cookie from "js-cookie";
import { Mixpanel } from "../app/mixpanel-instance";

function GetStartedButton() {
	let cookie_distinct_id = Cookie.get("distinct_id");
	function linkOnClick() {
		console.log("getstarted button clicked.");
		Mixpanel.track("getstarted btn clicked", {
			user: cookie_distinct_id,
		});
	}
	return (
		<Link
			to='/login'
			onClick={linkOnClick}
			className='h-[100%] w-[100%] max-h-[400px] border-2 bg-[#f8fb55] rounded-full opacity-80 pt-8 cursor-pointer filter drop-shadow-sm hover:bg-[#f4f800] hover:opacity-100 hover:drop-shadow-xl'
		>
			<p className='font-poppins font-semibold text-[24px] leading-[23px] aspect-video'>
				<span className=''>Get Started</span> <br></br>
				<span className='flex justify-center pt-2'>For Free</span>
			</p>
		</Link>
	);
}
export default GetStartedButton;
