import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import dataReducer from "../features/tabin/tabinSlice";

export const store = configureStore({
	reducer: {
		auth: authReducer,
		data: dataReducer,
	},
});
