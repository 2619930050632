import mixpanel from "mixpanel-browser";
import Cookie from "js-cookie";
const env = process.env.REACT_APP_ENV;
console.log("Environment: ", env);
const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

mixpanel.init(MIXPANEL_TOKEN, {
	debug: true,
	persistence: "localStorage",
	ignore_dnt: true,
	trackAutomaticEvents: true,
	loaded: function () {
		let mixpanel_distinct_id = JSON.stringify(mixpanel.get_distinct_id());
		// if cookie doesn't exist then create one
		if (!Cookie.get("distinct_id")) {
			Cookie.set("distinct_id", mixpanel_distinct_id);
		}

		// if cookie does exist but cookie distinct id does not match with mixpanel distinct id

		let cookie_distinct_id = Cookie.get("distinct_id");

		if (cookie_distinct_id) {
			if (cookie_distinct_id !== mixpanel_distinct_id)
				Cookie.set("distinct_id", mixpanel_distinct_id);
		}
	},
});

console.log("mixpanel-instance.js:", "mixpanel inititated");

let actions = {
	identify: (id) => {
		mixpanel.identify(id);
	},

	track: (name, props) => {
		mixpanel.track(name, props);
	},
	track_pageview: (page) => {
		mixpanel.track_pageview({ page });
	},
};

export const Mixpanel = actions;
