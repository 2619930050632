import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";

const user = JSON.parse(localStorage.getItem("user"));
const token = JSON.parse(localStorage.getItem("token"));

const initialState = {
	user: user ? user : null,
	isError: false,
	isSuccess: false,
	isLoading: false,
	isSignedIn: false,
	message: null,
	token: token ? token : null,
};

// Register User
export const register = createAsyncThunk(
	"auth/register",
	async (user, thunkAPI) => {
		try {
			return await authService.register(user);
		} catch (error) {
			const message =
				(error.response && error.response.data & error.data.message) ||
				error.message ||
				error.toString();

			return thunkAPI.rejectWithValue(message);
		}
	}
);

// Logout user
export const logout = createAsyncThunk("auth/logout", async (thunkAPI) => {
	try {
		return await authService.logout();
	} catch (error) {
		const message =
			(error.response && error.response.data & error.data.message) ||
			error.message ||
			error.toString();

		return thunkAPI.rejectWithValue(message);
	}
});

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		reset: (state) => {
			state.user = null;
			state.isError = false;
			state.isSuccess = false;
			state.isLoading = false;
			state.isSignedIn = false;
			state.token = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(register.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(register.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.isSignedIn = true;
				state.user = action.payload.user;
				state.token = action.payload.token;
			})
			.addCase(register.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				state.isSignedIn = false;
				state.user = null;
				state.token = null;
			})
			.addCase(logout.fulfilled, (state) => {
				state.user = null;
				state.token = null;
				state.isSignedIn = false;
			})
			.addCase(logout.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			}) // Inside extraReducers of dataSlice
			.addCase("RESET_DATA", (state) => {
				state.user = null;
				state.isError = false;
				state.isSuccess = false;
				state.isLoading = false;
				state.isSignedIn = false;
				state.token = null;
			});
	},
});

export const resetAuth = () => ({ type: "RESET_DATA" });
export const { reset } = authSlice.actions;
export default authSlice.reducer;
