import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { getUserTabins } from "../features/tabin/tabinSlice";
import Sidebar from "../components/Sidebar";
import Tabs from "../components/Tabs";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { Mixpanel } from "../app/mixpanel-instance";
const env = process.env.REACT_APP_ENV;

function Tabcase() {
	const TAG = "Tabcase ";

	const navigate = useNavigate();
	// const dispatch = useDispatch();

	const user = useSelector((state) => state.auth.user);
	const tabinData = useSelector((state) => state.data.tabin);

	const [binId, setBinId] = useState(null);
	const [tabins, setTabins] = useState(tabinData);

	if (env === "development" || env === "DEV") {
		console.log("logging....");
		console.log(TAG + "user", user);
		console.log(TAG + "tabins", tabins);
	}

	const onClickHandler = (e) => {
		if (env === "development" || env === "DEV") {
			console.log("e----", e);
		}
		let id = e.target.id;
		if (env === "development" || env === "DEV") {
			console.log("onclickHandler id:", id);
		}
		setBinId(id);
		Mixpanel.track("Tabin details", {
			tabin_id: id,
		});
	};

	useEffect(() => {
		Mixpanel.track_pageview();
		if (!tabins) {
			if (env === "development" || env === "DEV") {
				console.log("no bins, fetching user tabins");
			}
			//dispatch(getUserTabins(user.google_id));
		}
		if (env === "development" || env === "DEV") {
			console.log("render in tabcase");
		}
		// if (isError) {
		// 	console.log(message);
		// }
		if (!user) {
			navigate("/login");
			console.log("no user found");
		}

		ReactGA.send({
			hitType: "pageview",
			page: "/tabcase",
			title: "tabcase",
		});
	}, [tabins, user]);

	return (
		<>
			<Helmet>
				<title>
					Tabin | MyTabins - Context Switching Made Easy To Increase Work
					Productivity
				</title>
				<link rel='canonical' href='https://tabin.io/mytabins' />
			</Helmet>
			{tabins && tabins.length !== 0 ? (
				<div className='inline-flex w-[100%] h-[100%] pt-14'>
					<Sidebar tabins={tabins} onClickHandler={onClickHandler} />

					<Tabs tabins={tabins} setTabins={setTabins} binId={binId} />
				</div>
			) : (
				<div className='flex justify-center items-center pt-[100px] text-2xl'>
					NO TABIN GO CREATE SOME
				</div>
			)}
		</>
	);
}
export default Tabcase;
