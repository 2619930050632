import React from "react";
import ReactGA from "react-ga4";
import { useEffect, useState } from "react";
import { FaYoutube } from "react-icons/fa";
import YoutubeEmbed from "../components/YoutubeEmbed";
import { Mixpanel } from "../app/mixpanel-instance";

const HowTo = () => {
	const [selectedTab, setSelectedTab] = useState("create");

	const renderVideo = () => {
		switch (selectedTab) {
			case "create":
				return (
					<div className='grid justify-center mt-5 md:flex px-10'>
						<div className='pr-2 lg:pr-20'>
							<ul className='grid list-decimal justify-start items-center text-left '>
								<li className='pb-2'>
									To begin you must have Tabin Chrome Extension downloaded.
									<div className='flex m-1 p-1 justify-center items-center '>
										<a href='https://chromewebstore.google.com/detail/tabin-schedule-your-day/aikkjpmagfploeaedmhickkcadlfndno?hl=en&authuser=0'>
											<span className='flex max-h-[80px] h-auto w-auto px-12 justify-center items-center bg-[#AAF0DA] border-2 rounded-full hover:bg-[#48e6b4]'>
												<i className='flex flex-nowrap w-[full] h-[120px] justify-center items-center drop-shadow-sm opacity-70 hover:opacity-100 hover:drop-shadow-xl'>
													<img className='h-[55px]' src='tabin.png' alt='' />
													<span className='grid w-[140px] justify-center items-center mx-8 text-md xl:text-2xl'>
														<p>Get</p>
														<p>Tabin for chrome</p>
													</span>
												</i>
											</span>
										</a>
									</div>
								</li>
								<li className='pb-5'>
									Open all the URLs you would like in your Tabin.
								</li>
								<li className=''>
									Click on Tabin Extension icon.
									<div className='h-auto w-[200px] mt-2'>
										<img
											src='tabin_ext_toolbar.png'
											alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
										/>
									</div>
								</li>

								<li className='pt-5'>
									Give the Tabin a name and click create.
									<div className='h-auto w-[200px] mt-2'>
										<img
											src='create_tabin.jpeg'
											alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
										/>
									</div>
								</li>
							</ul>
						</div>
						<div className='mt-10'>
							<YoutubeEmbed embedId='pMoY97E23VY' />
						</div>
					</div>
				);
			case "schedule":
				return (
					<div className='grid justify-center mt-5 lg:flex px-10'>
						<div className='pr-4 xl:pr-20'>
							<ul className='grid list-decimal justify-start items-center text-left'>
								<li className='pb-5'>
									Make sure you have created a Tabin first, if not please do so.
								</li>
								<li className='pb-5'>Go to Schedule Tabins page</li>
								<li className=''>
									From the Tabins sidebar drag desired Tabin on to the calendar
									to schedule it.
									<div className='h-auto w-auto max-w-[600px] mt-2'>
										<img
											src='schedule_tabin.png'
											alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
										/>
									</div>
								</li>
							</ul>
						</div>
						<div className='mt-10'>
							<YoutubeEmbed embedId='7rG2M7fOMMQ' />
						</div>
					</div>
				);
			case "share":
				return (
					<div className='grid justify-center mt-5 lg:flex px-10'>
						<div className='pr-6 xl:pr-20'>
							<ul className='grid list-decimal justify-start items-center text-left'>
								<li className='pb-5'>
									Click My Tabins in top nav bar to go to My Tabins page
									<div className='h-auto w-[500px] mt-2'>
										<img
											src='my_tabins_page.png'
											alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
										/>
									</div>
								</li>
								<li className=''>
									From the left side bar select Tabin and click "copy Tabin
									Link"
									<div className='h-auto w-[200px] mt-2'>
										<img
											src='copy_tabin_link.png'
											alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
										/>
									</div>
								</li>

								<li className='pt-5'>Paste Tabin link to other apps.</li>
							</ul>
						</div>
						<div className='mt-10'>
							<YoutubeEmbed embedId='cFSwt3ZtU9I' />
						</div>
					</div>
				);
			case "delete":
				return (
					<div className='grid justify-center mt-5 lg:flex px-10'>
						<div className='pr-6 xl:pr-20'>
							<ul className='grid list-decimal justify-start items-center text-left'>
								<li className='pb-5'>
									Click My Tabins in top nav bar to go to My Tabins page
									<div className='h-auto w-[500px] mt-2'>
										<img
											src='my_tabins_page.png'
											alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
										/>
									</div>
								</li>
								<li className=''>
									Click Delete Button to delete Tabin
									<div className='h-auto w-[200px] mt-2'>
										<img
											src='delete_button.png'
											alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
										/>
									</div>
								</li>

								<p className='pt-5'>Deleting a Tabin is deleted permanently </p>
							</ul>
						</div>
						<div className='mt-10'>
							<YoutubeEmbed embedId='nOl9A8Vxio4' />;
						</div>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<div className='max-w-[full] mx-auto mt-8 mb-20'>
			<div className='flex justify-center'>
				<h1 className='text-4xl'>Tabin How-To Videos</h1>
			</div>

			{/* Navigation Bar */}
			<div className='flex justify-center space-x-8 mt-8 px-4'>
				<button
					className={`py-2 px-4 ${
						selectedTab === "create" ? "bg-blue-500 text-white" : "bg-gray-200"
					}`}
					onClick={() => setSelectedTab("create")}
				>
					Create Tabin
				</button>
				<button
					className={`py-2 px-4 ${
						selectedTab === "schedule"
							? "bg-blue-500 text-white"
							: "bg-gray-200"
					}`}
					onClick={() => setSelectedTab("schedule")}
				>
					Schedule Tabin
				</button>
				<button
					className={`py-2 px-4 ${
						selectedTab === "share" ? "bg-blue-500 text-white" : "bg-gray-200"
					}`}
					onClick={() => setSelectedTab("share")}
				>
					Share Tabin
				</button>
				<button
					className={`py-2 px-4 ${
						selectedTab === "delete" ? "bg-blue-500 text-white" : "bg-gray-200"
					}`}
					onClick={() => setSelectedTab("delete")}
				>
					Delete Tabin
				</button>
			</div>

			{/* Video Display */}
			<div className='flex justify-center mt-10'>
				<div className='w-full'>{renderVideo()}</div>
			</div>
		</div>
	);
};

export default HowTo;

// const Howto = () => {
// 	useEffect(() => {
// 		Mixpanel.track_pageview();
// 		ReactGA.send({
// 			hitType: "pageview",
// 			page: "/howto",
// 			title: "howto",
// 		});
// 	}, []);
// 	return (
// 		<div>
// 			<div className='columns-lg max-w-5xl mx-auto mt-10 mb-20'>
// 				<div className='flex justify-center'>
// 					<h1 className='text-4xl'>How to Create Tabin</h1>
// 				</div>
// 				<div className='flex justify-center mt-4'>
// 					<YoutubeEmbed embedId='pMoY97E23VY' />
// 				</div>
// 				<div className='flex justify-center pt-4 mt-10'>
// 					<h1 className='text-4xl'>How to Schedule Tabin</h1>
// 				</div>
// 				<div className='flex justify-center mt-4'>
// 					<YoutubeEmbed embedId='3WXjKCyx0lE' />
// 				</div>
// 				<div className='flex justify-center mt-10'>
// 					<h1 className='text-4xl'>How to Share Tabin</h1>
// 				</div>
// 				<div className='flex justify-center mt-4'>
// 					<YoutubeEmbed embedId='1o7r0f1mYFM' />
// 				</div>
// 				<div className='flex justify-center pt-4 mt-10'>
// 					<h1 className='text-4xl'>How to Delete Tabin</h1>
// 				</div>
// 				<div className='flex justify-center mt-4'>
// 					<YoutubeEmbed embedId='Ln1ei55l92I' />
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

// export default Howto;
