import React from "react";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import { getTabinByID } from "../features/tabin/tabinSlice";
import { Mixpanel } from "../app/mixpanel-instance";
import { Helmet } from "react-helmet";

const env = process.env.REACT_APP_ENV;

function Open() {
	const queryParameters = new URLSearchParams(window.location.search);
	const tabinID = queryParameters.get("id");

	if (env === "development" || env === "DEV") {
		console.log("id", tabinID);
	}

	const dispatch = useDispatch();
	const [tabinTitle, setTabinTitle] = useState();
	const [bin, setBin] = useState();
	const [dataLoaded, setDataLoaded] = useState(false);

	const messagesEndRef = useRef(null);

	const getTabinFromDB = async () => {
		const response = await dispatch(getTabinByID(tabinID));
		let data = response.payload.tabs;
		setBin(data);
		setTabinTitle(response.payload.title);
		ReactGA.event({
			category: "User Engagement",
			action: "User used Tabin Link to open Tabin",
			label: "Tabin Interaction",
			value: response.payload.title,
		});
	};

	const openTabin = () => {
		if (bin) {
			for (let tab of bin) {
				window.open(tab.url, "_blank");
			}
		}
	};
	if (!dataLoaded) {
		getTabinFromDB();
		setDataLoaded(true);
	}

	const copyBtnClickHandler = () => {
		let url = document.location.href;
		navigator.clipboard.writeText(url);
	};

	const openBtnClickHandler = () => {
		openTabin();
	};

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		Mixpanel.track_pageview();
		scrollToBottom();
		ReactGA.send({
			hitType: "pageview",
			page: "/open",
			title: "open",
		});
	}, []);

	return (
		<div className='max-w-[1600px] ml-10 pb-10 sm:pl-[100px] md:pl-[200px]'>
			<Helmet>
				<title>Tabin | Open - Share Multiple Links With A Single Link</title>
				<link rel='canonical' href='https://tabin.io/open' />
			</Helmet>
			<h1 className='flex items-center justify-center pt-10 text-[40px] text-transform: uppercase '>
				{tabinTitle}
			</h1>

			{bin ? (
				<>
					<ul className='flex-row pt-4 pb-6 '>
						{bin.map((tab) => {
							return (
								<>
									<a
										className='flex items-center pt-5 border-b-2 hover:bg-[#bdffea]'
										href={tab.url}
										target='_blank'
										rel='noreferrer'
									>
										<li className='flex w-[700px]'>
											<img
												src={tab.fav_icon_url}
												alt=''
												className='mr-3 h-[40px] w-[40px] webkitfilter'
											></img>
											<p className='text-transform: uppercase'>{tab.title}</p>
										</li>
									</a>
								</>
							);
						})}
					</ul>
					<div className='w-[100%] pb-[150px] justify-center items-center mt-5 xs:flex-row sm:flex'>
						<button
							className='mt-2 justify-center items-center webkitfilter font-bold text-white bg-green-500 border-2 border-black p-7 mr-2 cursor-pointer hover:bg-green-700 xs:w-[80%] md:w-[300px]'
							type='button'
							onClick={openBtnClickHandler}
						>
							Open Tabin
						</button>

						<button
							className='mt-2 justify-center items-center font-bold text-white bg-blue-400 border-2 border-black p-7  mr-2 cursor-pointer hover:bg-blue-700 xs:w-[80%] md:w-[300px]'
							type='button'
							onClick={copyBtnClickHandler}
						>
							Copy Tabin Link
						</button>
					</div>
				</>
			) : (
				<div className='flex h-[400px] justify-center items-center text-2xl mr-[50px] sm:mr-[100px]'>
					Tabin not found, please check URL
				</div>
			)}
		</div>
	);
}
export default Open;
