import { React, useEffect } from "react";
import { Mixpanel } from "../app/mixpanel-instance";
import { Helmet } from "react-helmet";

const Privacy = () => {
	useEffect(() => {
		Mixpanel.track_pageview();
	}, []);
	return (
		<div className='h-[100%]'>
			<Helmet>
				<title>
					Tabin | Privacy - Context Switching Made Easy To Increase Work
					Productivity
				</title>
				<link rel='canonical' href='https://tabin.io/privacy' />
			</Helmet>
			<div className='flex-row pt-6'>
				<div className='grid justify-center items-center'>
					<h1 className='text-4xl p-1'>Tabin.io Inc</h1>
					<h1 className='text-4xl p-1'>Privacy Policy</h1>
					<h4 className='p-1'>last updated Oct 21, 2024</h4>
				</div>
				<div className='flex justify-center mt-6'>
					<div className='w-[50%] flex justify-center text-left mr-10'>
						<ul>
							<li className='text-blue-500 hover:text-blue-800'>
								<a href='#introduction'>Introduction</a>
							</li>
							<li className='text-blue-500 hover:text-blue-800'>
								<a href='#personal_data_we_collect'>Personal Data We Collect</a>
							</li>
							<li className='text-blue-500 hover:text-blue-800'>
								<a href='#how_we_use_personal_data'>How We Use Personal Data</a>
							</li>
							<li className='text-blue-500 hover:text-blue-800'>
								{" "}
								<a href='#sharing_and_disclosure_of_personal_data'>
									Sharing and Disclosure of Personal Data
								</a>
							</li>
							<li className='text-blue-500 hover:text-blue-800'>
								<a href='#data_retention'>Data Retention</a>
							</li>
							<li className='text-blue-500 hover:text-blue-800'>
								<a href='#update_your_information'>Update Your Information</a>
							</li>
						</ul>
					</div>
					<div className='w-[50%] flex justify-start text-left'>
						<ul>
							<li className='text-blue-500 hover:text-blue-800'>
								<a href='#children'>Children</a>
							</li>
							<li className='text-blue-500 hover:text-blue-800'>
								<a href='#your_rights'> Your Rights</a>
							</li>
							{/* <li className='text-blue-500 hover:text-blue-800'>
								<a href='#'> Link to Other Websites or Services</a>
							</li> */}
							<li className='text-blue-500 hover:text-blue-800'>
								<a href='#security'>Security</a>
							</li>
							<li className='text-blue-500 hover:text-blue-800'>
								<a href='#cross_border_data_transfers'>
									Cross-Border Data Transfers
								</a>
							</li>
							{/* <li className='text-blue-500 hover:text-blue-800'>
								{" "}
								<a href='#data_privacy_framework_notification'>Data Privacy Framework Notification</a>
							</li> */}
							<li className='text-blue-500 hover:text-blue-800'>
								<a href='#your_choices'>Your Choices</a>
							</li>
							<li className='text-blue-500 hover:text-blue-800'>
								<a href='#changes_to_the_privacy_policy'>
									Changes to the Privacy Policy
								</a>
							</li>
							<li className='text-blue-500 hover:text-blue-800'>
								{" "}
								<a href='#contact_us'>Contact Us</a>
							</li>
						</ul>
					</div>
				</div>
				<div className='m-10'>
					<div id='introduction' className='my-6 pb-3'>
						<h1 className='text-2xl font-semibold pb-2'>Introduction</h1>
						<div className=''>
							Tabin is a productivity app designed to help users switch between
							context such as meetings and work efficiently. The app is
							available globally and is operated by Tabin Company. This policy
							applies to all users who access Tabin’s services.
							<br></br>
							<br></br>
							Tabin.io Inc. (“Tabin,” “we,” “us,” or “our”) has prepared this
							Privacy Policy to explain what Personal Data (defined below) we
							collect, how we use and share that data, and your choices
							concerning our data practices.
							<br></br>
							<br></br>
							This Privacy Policy explains what Personal Data we collect, how we
							use and share that data, and your choices concerning our data
							practices. This Privacy Policy is incorporated into and forms part
							of our Terms of Service. ‍<br></br>
							<br></br>
							Through our SaaS application (the “App”) and our chrome extension
							(the "Extension"), Tabin helps you to switch between context such
							as meetings and work efficiently. Such tools, including
							https://tabin.io/ (the “Site”), our Tabin chrome extension (the
							"Extension") and any other products and services that link to this
							Privacy Policy are referred to collectively in this Privacy Policy
							as the “Service”.
							<br></br>
							<br></br>
							Before using the Service or submitting any Personal Data to Tabin,
							please review this Privacy Policy carefully and contact us if you
							have any questions to support@tabin.io. By using the Service, you
							agree to the practices described in this Privacy Policy. If you do
							not agree to this Privacy Policy, please do not access the Site or
							download the Extension otherwise use the Service.
							<br></br>
							<br></br>
							{/* If your employer pays for your access to the Service, we will
							process your work calendar information as a “data processor” on
							behalf of your employer pursuant to our agreement(s) with your
							employer, rather than this Privacy Policy. */}
						</div>
					</div>
					<div id='personal_data_we_collect' className='my-6 pb-3'>
						<h1 className='text-2xl font-semibold pb-2'>
							Personal Data We Collect
						</h1>
						<div className=''>
							Personal Data you provide: We collect the following Personal Data
							when you use our Services:
							<br></br>
							<br></br>
							‍We collect information that alone or in combination with other
							information in our possession could be used to identify you
							(“Personal Data”) as follows:
							<ul className='list-disc ml-10 my-6'>
								<li>
									Contact Data: We collect your name and email when you create
									an account to use the Services or otherwise interact with us
									through our Site.
								</li>
								<li>
									Calendar Data: For calendars you authorize to connect to the
									Tabin App, We collect and use your Google calendar data
									including event titles to help creating Tabins. . We DO NOT
									STORE any Calendar Data.
								</li>
								<li>
									App Data: We may collect other Personal Data from you, to the
									extent you submit any to us in connection with your use of the
									Service (e.g. when you create Tabins, Notes, Scheduling
									Tabins)
								</li>
							</ul>
							<div>
								Google Calendar Integration: In order to provide the Services,
								we will authenticate your Google Calendar account to gather the
								event titles of your calendar data. Through this integration, we
								will have access to your calendar and any information available
								there (“Google Calendar Data”). Notwithstanding anything else in
								this Privacy Policy, we (1) only use the necessary Google
								Calendar Data to provide the Services; (2) do not transfer
								Google Calendar Data to third parties except as necessary to
								provide the Services, as required by law, or in connection with
								a merger, acquisition, or sale of assets where we provide notice
								to users; (3) do not use the Google Calendar Data for serving
								advertisements; and (4) do not permit humans to read Google
								Calendar Data, except (a) if we obtain your affirmative consent,
								(b) as necessary for security purposes or to comply with
								applicable law or (c) our use is limited to internal operations,
								such as resolving support issues or analyzing data to improve
								the Service.
							</div>
							<div>
								Google API Services Disclosure: Tabin's use and transfer of
								information received from Google APIs to any other app will
								adhere to{" "}
								<a
									href='https://developers.google.com/terms/api-services-user-data-policy'
									className='text-blue-500 hover:text-blue-800'
								>
									Google API Service User Data Policy
								</a>
								, including its Limited Use requirements. Our app strictly
								complies with all conditions specified in the Limited Use policy
								from Google:
								<ul className='list-disc ml-10 my-6'>
									<li>
										We do not allow humans to read user data unless we've
										obtained the user's affirmative agreement.
									</li>
									<li>
										We do not use or transfer user data for serving ads -
										including retargeting, personalized or interest-based
										advertising.
									</li>
									<li>
										We limit use of data to providing or improving user-facing
										features.
									</li>
									<li>
										We only transfer data to others if necessary to provide or
										improve user-facing features.
									</li>
								</ul>
							</div>
							<div>
								Personal Data We Receive Automatically From Your Use of the
								Service: When you visit, use and interact with the Service, we
								may receive certain information about your visit, use or
								interactions. For example, we may monitor the number of people
								that visit the Service, peak hours of visits, which page(s) are
								visited, the domains our visitors come from (e.g., google.com,
								yahoo.com, etc.), and which browsers people use to access the
								Service (e.g., Chrome, Firefox, Microsoft Internet Explorer,
								etc.), broad geographical information, and navigation pattern.
								In particular, the following information is created and
								automatically logged in our systems:
								<ul className='list-disc ml-10 my-6'>
									<li>
										Log data: Information that your browser automatically sends
										whenever you visit the Site (“log data”). Log data includes
										your Internet Protocol address, browser type and settings,
										the date and time of your request, and how you interacted
										with the Site. We anonymize any logs containing Personal
										Data before sending it to our sub-processors.
									</li>
									<li>
										Device information: Includes name of the device, operating
										system, and browser you are using. Information collected may
										depend on the type of device you use and its settings.
									</li>
									<li>
										Usage Information: We collect information about how you use
										our Service, such as the types of content that you view or
										engage with, the features you use, the actions you take, and
										the time, frequency and duration of your activities.
									</li>
								</ul>
								Cookies: We use cookies for a variety of reasons including to
								operate and administer our Site, gather usage data on our Site
								and improve your experience on it. A “cookie” is a piece of
								information sent to your browser by a website you visit. Cookies
								can be stored on your computer for different periods of time.
								Some cookies expire after a certain amount of time, or upon
								logging out (session cookies), others survive after your browser
								is closed until a defined expiration date set in the cookie (as
								determined by the third party placing it), and help recognize
								your computer when you open your browser and browse the Internet
								again (persistent cookies). We will not place cookies on your
								device without your express consent, except where the cookies
								are necessary for us to provide you with our Service that you
								have requested. You can provide or decline your consent to the
								use of cookies on the pop-up cookie banner that appears when you
								first visit our site
								<br></br>
								<br></br>
								<h1 className='font-semibold'>Tabin Chrome Extension</h1>
								Tabin Chrome Extension does not keep track of any sites or tabs
								you have visited or are visiting. Any site information Tabin
								receives is done so by the users when creating a Tabin.
							</div>
						</div>
					</div>
					<div id='how_we_use_personal_data' className='my-6 pb-3'>
						<h1 className='text-2xl font-semibold pb-2'>
							How We Use Personal Data
						</h1>
						<div className=''>
							We may use personal data we collect to:
							<ul className='list-disc ml-10 my-6'>
								<li>
									To provide the Service, we gather Personal Data from you,
									including your email and name (via your Google Account), your
									user-generated content (via features in Tabin like Tabins and
									Scheduling Tabins), as well as your Google Calendar Data
									including event details.
								</li>
								<li>To analyze how you interact with our Service.</li>
								<li>
									To maintain and improve the content and functionality of the
									Service.
								</li>
								<li>To develop new products and services.</li>
								<li>
									To prevent fraud, criminal activity, or misuses of our
									Service, and to ensure the security of our IT systems,
									architecture and networks.
								</li>
								<li>
									To comply with legal obligations and legal process and to
									protect our rights, privacy, safety or property, and/or that
									of our affiliates, you or other third parties.
								</li>
							</ul>
						</div>
					</div>
					<div
						id='sharing_and_disclosure_of_personal_data'
						className='my-4 pb-3'
					>
						<h1 className='text-2xl font-semibold pb-2'>
							Sharing and Disclosure of Personal Data
						</h1>
						<div className=''>
							We prioritize protecting your information and in certain
							circumstances we may share your Personal Data with third parties
							without further notice to you, unless required by the law, as set
							forth below: Tabin is a productivity app designed to help users
							switch between tasks and contexts efficiently. The app is
							available globally and is operated by Tabin Company. This policy
							applies to all users who access Tabin’s services.
						</div>
						<div>
							Business Transfers: If we are involved in a merger, acquisition,
							financing due diligence, reorganization, bankruptcy, receivership,
							sale of all or a portion of our assets, or transition of service
							to another provider, your Personal Data and other information may
							be shared in the diligence process with counterparties and others
							assisting with the transaction and transferred to a successor or
							affiliate as part of that transaction along with other assets.
						</div>
						<div>
							Legal Requirements: If required to do so by law or in the good
							faith belief that such action is necessary to (i) comply with a
							legal obligation, including to meet national security or law
							enforcement requirements, (ii) protect and defend our rights or
							property, (iii) prevent fraud, (iv) act in urgent circumstances to
							protect the personal safety of users of the Services, or the
							public, or (v) protect against legal liability.
						</div>
						<div>
							Other Users: Some of your Personal Data may be visible to users
							and administrators of your organization if you sign-up for the
							Service with an email address under that organization. Data will
							never be shared with anyone outside of your domain. Calendar Data
							or App Data would only be shared with other users if you
							specifically direct us to share such Personal Data through the
							Service.
						</div>
					</div>
					<div id='data_retention' className='my-6 py-6'>
						<h1 className='text-2xl font-semibold pb-2'>Data Retention</h1>
						<div className=''>
							We keep Personal Data for as long as reasonably necessary for the
							purposes described in this Privacy Policy, while we have a
							business need to do so, or as required by law (e.g. for tax,
							legal, accounting or other purposes), whichever is the longer.
							Tabin proudly offers a self-service delete capability which
							enables you to remove your account and all associated Personal
							Data and Google Calendar Data with Tabin. You may also email
							support@tabin.io to do this on your behalf or confirm that your
							data has been deleted. For more information, please visit the help
							article:
						</div>
					</div>
					<div id='update_your_information' className='my-6 pb-3'>
						<h1 className='text-2xl font-semibold pb-2'>
							Update Your Information
						</h1>
						<div className=''>
							Please log in to your account if you need to change or correct
							your Personal Data.
						</div>
					</div>
					<div id='children' className='my-6 pb-3'>
						<h1 className='text-2xl font-semibold pb-2'>Children</h1>
						<div className=''>
							Our Service is not directed to children who are under the age of
							13. Tabin does not knowingly collect Personal Data from children
							under the age of 13. If you have reason to believe that a child
							under the age of 13 has provided Personal Data to Tabin through
							the Service please contact us and we will start to delete that
							information from our databases.
						</div>
					</div>
					<div id='your_rights' className='my-6 pb-3'>
						<h1 className='text-2xl font-semibold pb-2'>Your Rights</h1>
						<div className=''>
							You may be able to exercise certain privacy right. The rights
							available to you depend on our reason for processing your personal
							information and the requirements of applicable laws (i.e., your
							rights will vary depending on whether you are located in, for
							example, the European Union, the United Kingdom, Brazil, or
							California). Specifically, you may have the following rights
							<ul className='list-disc ml-10 my-6'>
								<li>
									The right to learn more about what Personal Data of yours is
									being processed, how and why such information is processed and
									the third parties who have access to such Personal Data. We
									have made this information available to you without having to
									request it by including it in this Privacy Policy.
								</li>
								<li>The right to access your Personal Data.</li>
								<li>The right to rectify/correct your Personal Data</li>
								<li>
									The right to restrict the use of your Personal Data where
									permitted under applicable law.
								</li>
								<li>
									The right to request that your Personal Data is erased/deleted
									where permitted under applicable law.
								</li>
								<li>
									The right to data portability (i.e. receive your Personal Data
									or have it transferred to another controller in a structured,
									commonly-used, machine readable format) where permitted under
									applicable law.
								</li>
								<li>
									The right to object to processing of your Personal Data or to
									direct us not to share your Personal Data with a
									non-affiliated third party where permitted under applicable
									law.
								</li>
								You also have the right to lodge a complaint with the relevant
								authority (as applicable) or a supervisory authority in the UK
								or EU member state of your usual residence or place of work or
								of the place of the alleged breach, if you consider that the
								processing of your Personal Data carried out by Tabin or any of
								our affiliates or third-party service providers, has breached
								data protection laws.
							</ul>
						</div>
					</div>
					<div id='security' className='my-6 pb-3'>
						<h1 className='text-2xl font-semibold pb-2'>Security</h1>
						<div className=''>
							We take security very seriously. We implement reasonable
							technical, administrative, and organizational controls designed to
							protect the confidentiality, integrity, availability and
							resilience of Personal Data, both online and offline from loss,
							misuse, and unauthorized access, disclosure, alteration or
							destruction. These measures include utilization of enryption
							technologies and secure software development.
						</div>
					</div>
					<div id='cross_border_data_transfers' className='my-6 pb-3'>
						<h1 className='text-2xl font-semibold pb-2'>
							Cross-border Data Transfers
						</h1>
						<div className=''>
							For individuals in the European Economic Area, the United Kingdom,
							Canada or any other jurisdiction outside of the United States with
							laws governing personal data collection, please be aware that by
							using our Service, you understand and acknowledge that your
							Personal Data will be transferred from your location to our
							facilities and servers in the United States where we or our
							sub-processors have operations.
						</div>
					</div>
					<div id='your_choices' className='my-6 pb-3'>
						<h1 className='text-2xl font-semibold pb-2'>Your Choices</h1>
						<div className=''>
							In signing up for the Service, you are choosing to provide and
							share both your Personal Data and your Google Calendar Data to
							Tabin. If you do not wish to share that data with Tabin, please do
							not sign up for the Service. Tabin proudly offers a self-service
							delete capability which enables you to remove your account and all
							associated Personal Data and Google Calendar Data with Tabin.
						</div>
					</div>
					<div id='changes_to_the_privacy_policy' className='my-6 pb-3'>
						<h1 className='text-2xl font-semibold pb-2'>
							Changes to the Privacy Policy
						</h1>
						<div className=''>
							The Service, and our business may change from time to time. As a
							result we may change this Privacy Policy at any time. When we do
							we will post an updated version on this page, unless another type
							of notice is required by the applicable law. By continuing to use
							our Service or providing us with Personal Data after we have
							posted an updated Privacy Policy, or notified you by other means
							if applicable, you consent to the revised Privacy Policy and
							practices described in it.
						</div>
					</div>
					<div id='contact_us' className='my-6 pb-3'>
						<h1 className='text-2xl font-semibold pb-2'>Contact Us</h1>
						<div className=''>
							If you have any questions about our Privacy Policy or information
							practices, please feel free to contact us at our designated
							request address:{" "}
							<p className=' text-blue-500'> support@tabin.io</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Privacy;
