import React from "react";
// import { useEffect } from "react";
import { useSelector } from "react-redux";
// import Logo from "../components/Logo";
// import Logofour from "../components/Logofour";
// import { getTabinCenterTabins } from "../features/tabin/tabinSlice";

function TabinExplorer() {
	// const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);

	// const tabin_center_tabins = localStorage.getItem("tabin_center_tabins");

	// useEffect(() => {
	// 	if (!tabin_center_tabins) {
	// 		dispatch(getTabinCenterTabins());
	// 	}
	// }, []);
	return (
		<div className=''>
			<div className='text-black pageintrotext'>Welcome {user.first_name}</div>

			<div className='flex w-[full] h-[auto] justify-center items-center'>
				<img
					src='under_construction.svg'
					alt=''
					className='flex h-[40px] w-[40px] m-5'
				/>
				<h1 className='text-xl'> Page Under Construction </h1>
				<img
					src='under_construction.svg'
					alt=''
					className='flex h-[40px] w-[40px] m-5'
				/>
			</div>
			{/* <div className='logbox'>
				<div className='logocentered'>
					<Logofour />
				</div>
			</div> */}
		</div>
	);
}
export default TabinExplorer;
