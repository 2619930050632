import React from "react";
import { useEffect, useRef, useState } from "react";

import {
	FaCalendarAlt,
	FaSignInAlt,
	FaBriefcase,
	FaSignOutAlt,
	FaHome,
	FaQuestion,
	FaMizuni,
} from "react-icons/fa";
import { SiBuymeacoffee } from "react-icons/si";
import { GrResources } from "react-icons/gr";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Mixpanel } from "../app/mixpanel-instance";

const Navbar = () => {
	const { user } = useSelector((state) => state.auth);
	const [toggle, setToggle] = useState(false);
	// State to handle the dropdown visibility
	const [showDropdown, setShowDropdown] = useState(false);
	const dropdownRef = useRef(null); // Reference for dropdown menu

	const handleToggleDropdown = () => {
		setShowDropdown((prev) => !prev); // Toggle the dropdown
	};

	const handleLogout = () => {
		setShowDropdown(false);
		Mixpanel.track("Logout link NavBar Clicked");
		// Add your logout functionality here
	};

	const handleSetting = () => {
		setShowDropdown(false);
		Mixpanel.track("Setting link NavBar Clicked");
		// Add your logout functionality here
	};

	// Close dropdown if clicked outside
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setShowDropdown(false);
			}
		};

		// Add event listener when dropdown is open
		if (showDropdown) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}

		// Cleanup the event listener on component unmount
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [showDropdown]);
	return (
		<nav className='flex border-b-4 w-full py-6 justify-between items-center navbar'>
			<img
				src='tabin.png'
				alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
				className='w-[50px] mr-10'
			></img>
			<ul className='list-none hidden justify-end items-center flex-1 md:flex'>
				{user ? (
					<>
						<div className='font-poppins font-normal cursor-pointer text-[16px] mr-10 hover:font-semibold lg:text-[18px]'>
							<Link
								className='flex'
								to='/'
								onClick={() => {
									Mixpanel.track("Home link NavBar Clicked");
								}}
							>
								<i className='mt-1'>
									<FaHome />
								</i>
								Home
							</Link>
						</div>
						<div className='font-poppins font-normal cursor-pointer text-[16px] mr-10 hover:font-semibold lg:text-[18px]'>
							<Link
								className='flex'
								to='/mytabins'
								onClick={() => {
									Mixpanel.track("My Tabins link NavBar Clicked");
								}}
							>
								<i className='mt-1'>
									<FaBriefcase />
								</i>
								My Tabins
							</Link>
						</div>
						<div className='font-poppins font-normal cursor-pointer text-[16px] mr-10 hover:font-semibold lg:text-[18px]'>
							<Link
								className='flex'
								to='/schedule'
								onClick={() => {
									Mixpanel.track("Schedule link NavBar Clicked");
								}}
							>
								<i className='mt-1'>
									<FaCalendarAlt />
								</i>
								Schedule Tabins
							</Link>
						</div>
						<div className='font-poppins font-normal cursor-pointer text-[16px] mr-10 hover:font-semibold lg:text-[18px]'>
							<Link
								className='flex'
								to='/howto'
								onClick={() => {
									Mixpanel.track("Howto link NavBar Clicked");
								}}
							>
								<i className='mt-1'>
									<FaQuestion />
								</i>
								How To
							</Link>
						</div>
						{user.admin || user.email === "ssingh0188@gmail.com" ? (
							<div className='font-poppins font-normal cursor-pointer text-[16px] mr-10 hover:font-semibold lg:text-[18px]'>
								<Link
									className='flex'
									to='/dashboard'
									onClick={() => {
										Mixpanel.track("Dashboard link NavBar Clicked");
									}}
								>
									<i className='mt-1'>
										<FaBriefcase />
									</i>
									Admin Dashboard
								</Link>
							</div>
						) : (
							<></>
						)}
						<div className='font-poppins font-normal cursor-pointer text-[16px] hover:font-semibold lg:text-[18px]'>
							{/* <Link
								className='flex'
								to='/logout'
								onClick={() => {
									Mixpanel.track("Logout link NavBar Clicked");
								}}
							> */}
							<div className='font-poppins font-normal cursor-pointer text-[16px] mr-10 hover:font-semibold lg:text-[18px] relative'>
								{/* Profile Image */}
								<div className='flex' onClick={handleToggleDropdown}>
									<div className='h-[30px] w-[30px] border-2 rounded-full overflow-hidden'>
										<img
											src={user.image_url}
											alt='User Profile'
											className='w-full h-full object-cover'
										/>{" "}
									</div>
									<p className='flex items-center ml-1'>Account</p>
								</div>

								{/* Dropdown Menu */}
								{showDropdown && (
									<div
										ref={dropdownRef}
										className='absolute right-0 mt-2 w-[150px] bg-white border border-gray-300 rounded-md shadow-lg z-50'
									>
										<ul>
											<li className='px-4 py-2 hover:bg-gray-100'>
												<Link to='/settings' onClick={handleSetting}>
													Settings
												</Link>
											</li>
											<li className='px-4 py-2 hover:bg-gray-100'>
												<Link to='/logout' onClick={handleLogout}>
													Logout
												</Link>
											</li>
										</ul>
									</div>
								)}
							</div>

							{/* </Link> */}
						</div>
					</>
				) : (
					<>
						<div className='font-poppins font-normal cursor-pointer text-[18px] mr-10 hover:font-semibold'>
							<Link
								className='flex'
								to='/'
								onClick={() => {
									Mixpanel.track("Home link NavBar Clicked");
								}}
							>
								<i className='mt-1'>
									<FaHome />
								</i>
								Home
							</Link>
						</div>
						<div className='font-poppins font-normal cursor-pointer text-[18px] mr-10 hover:font-semibold'>
							<Link
								className='flex'
								to='/pricing'
								onClick={() => {
									Mixpanel.track("Pricing link NavBar Clicked");
								}}
							>
								<i className='mt-1'>
									<SiBuymeacoffee />
								</i>
								Pricing
							</Link>
						</div>
						<div className='font-poppins font-normal cursor-pointer text-[18px] mr-10 hover:font-semibold'>
							<Link
								className='flex'
								to='/howto'
								onClick={() => {
									Mixpanel.track("Howto link NavBar Clicked");
								}}
							>
								<i className='mt-1'>
									<FaQuestion />
								</i>
								How To
							</Link>
						</div>
						<div className='font-poppins font-normal cursor-pointer text-[18px] mr-10 hover:font-semibold'>
							<Link
								className='flex'
								to='/extensions'
								onClick={() => {
									Mixpanel.track("Resources link NavBar Clicked");
								}}
							>
								<i className='mt-1'>
									<GrResources />
								</i>
								Tabin Extension
							</Link>
						</div>
						<div className='font-poppins font-normal cursor-pointer text-[18px] hover:font-semibold'>
							<Link
								className='flex'
								to='/login'
								onClick={() => {
									Mixpanel.track("Login link NavBar Clicked");
								}}
							>
								<i className='mt-1'>
									<FaSignOutAlt />
								</i>
								Login
							</Link>
						</div>
					</>
				)}
			</ul>
			<div className='flex flex-1 justify-end items-center md:hidden'>
				{toggle ? (
					<AiOutlineClose
						className='w-[28px] h-[28px] object-contain'
						onClick={() => setToggle((prev) => !prev)}
					/>
				) : (
					<AiOutlineMenu
						className='w-[28px] h-[28px] object-contain'
						onClick={() => setToggle((prev) => !prev)}
					/>
				)}
				<div
					className={`${
						toggle ? "flex" : "hidden"
					} p-6 bg-[#AAF0DA] absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar border z-40`}
				>
					<ul
						className='list-none flex flex-col justify-end items-center flex-1'
						onClick={() => setToggle((prev) => !prev)}
					>
						{user ? (
							<>
								<div className='font-poppins font-normal cursor-pointer text-[18px] mb-4 w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/'
										onClick={() => {
											Mixpanel.track("Home link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<FaHome />
										</i>
										Home
									</Link>
								</div>
								<div className='font-poppins font-normal cursor-pointer text-[18px] mb-4 w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/mytabins'
										onClick={() => {
											Mixpanel.track("MyTabins link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<FaBriefcase />
										</i>
										My Tabins
									</Link>
								</div>
								<div className='font-poppins font-normal cursor-pointer text-[18px] mb-4 w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/schedule'
										onClick={() => {
											Mixpanel.track("Schedule link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<FaCalendarAlt />
										</i>
										Schedule Tabins
									</Link>
								</div>
								<div className='font-poppins font-normal cursor-pointer text-[18px] mb-4 w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/howto'
										onClick={() => {
											Mixpanel.track("Howto link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<FaQuestion />
										</i>
										How To
									</Link>
								</div>
								<div className='font-poppins font-normal cursor-pointer text-[18px] w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/logout'
										onClick={() => {
											Mixpanel.track("Logout link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<FaSignInAlt />
										</i>
										Logout
									</Link>
								</div>
							</>
						) : (
							<>
								<div className='font-poppins font-normal cursor-pointer text-[18px] mb-4 w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/'
										onClick={() => {
											Mixpanel.track("Home link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<FaHome />
										</i>
										Home
									</Link>
								</div>
								<div className='font-poppins font-normal cursor-pointer text-[18px] mb-4 w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/pricing'
										onClick={() => {
											Mixpanel.track("Pricing link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<SiBuymeacoffee />
										</i>
										pricing
									</Link>
								</div>
								<div className='font-poppins font-normal cursor-pointer text-[18px] mb-4 w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/howto'
										onClick={() => {
											Mixpanel.track("Howto link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<FaQuestion />
										</i>
										How To
									</Link>
								</div>
								<div className='font-poppins font-normal cursor-pointer text-[18px] mb-4 w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/extensions'
										onClick={() => {
											Mixpanel.track("Resources link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<GrResources />
										</i>
										Tabin Extension
									</Link>
								</div>
								<div className='font-poppins font-normal cursor-pointer text-[18px] w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/login'
										onClick={() => {
											Mixpanel.track("Login link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<FaSignInAlt />
										</i>
										Login
									</Link>
								</div>
							</>
						)}
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
